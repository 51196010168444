<div class="row container-fluid navBar">

  <div class="col-md-2 col-sm-6 pt-1 d-flex justify-content-start">
    <a class="navItem" [routerLink]="'/'">
      <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />
    </a>
  </div>

  <div class="col-md-10 d-none d-md-block typography">
    <div class="row  pt-3 justify-content-end" style="margin-top: 7px;">
      <div class="col">
        <div><a class="navItem" [routerLink]="'/'" fragment="" [routerLinkActive]="['active']">Accueil</a>
        </div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/conditions'" [routerLinkActive]="['active']" fragment="">Condition
            générales
            d’utilisation</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/conditionsGénérales'" [routerLinkActive]="['active']" fragment=""
            style="color: #0078E5">Conditions
            générales de vente</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/charte'" [routerLinkActive]="['active']" fragment="">Charte
            de bonne conduite</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/confidentialityChart'" [routerLinkActive]="['active']"
            fragment="">Charte de
            confidentialité</a></div>
      </div>

    </div>
  </div>
</div>

<div class="main-div">
  <h1 class="h1">Conditions générales de vente</h1>
  <br>
  <br>
  <p>
    Dénomination sociale : Wiplace
    <br>
    Adresse : 11 rue Charles Rossignol 91600 Savigny-Sur-Orge
    <br>
    Contact : contactus@Wiplace.me
    <br>
    Forme juridique : SAS
    <br>
    Montant du capital social : 5000 euros
    <br>
    Siret : 90110597300019
    <br>
    Nom du directeur de la publication : Camille CAMBERLIN
  </p>
  <br>
  <p>
    <!-- Le service d’échange de maisons proposé par Wiplace est édité par Wiplace, société de droit ……. (Référence de
    l’entreprise, Siret etc.). <br><br> -->

    Le site Wiplace.com ainsi que l’application Wiplace propose à ses membres un service de mise en relation
    permettant de s’échanger les biens immobiliers d’habitation dont ils sont propriétaires ou locataires, pour une
    durée limitée.<br><br>

    Wiplace est avant tout une communauté de voyageurs qui favorisent les échanges humains et respectent le bien être

    d’autrui. L’échange est un principe gagnant-gagnant, l’utilisateur met gratuitement son logement à disposition d'un
    autre utilisateur (dans son intégralité ou en partie) et inversement</p>

  <br>
  <br>

  <h4>Utilisation des services gratuits</h4>

  <br>

  <p>Tout individu inscrit sur la plateforme Wiplace bénéficie gratuitement et sans réserve de l’accès aux
    services énumérés ci-après :</p>

  <ul>
    <li>Effectuer une recherche</li><br>
    <li>Consulter les annonces de logements disponibles à l’échange</li><br>
    <li>Consulter le profil et les avis des place-trotters</li><br>
    <li>Consulter les dates de disponibilité</li><br>
    <li>Sauvegarder ses annonces favorites</li><br>
    <li>Discuter avec d’autres place-trotters via la messagerie</li>
  </ul>

  <p>L’accès gratuit à la plateforme ne permet pas à l’utilisateur de concrétiser un échange. Seul le paiement de
    l’abonnement annuel Wiplace permet de réaliser un échange et de profiter de nos outils de sécurité. </p>

  <br>
  <br>

  <h4>Utilisation des services payants </h4>

  <br>

  <p>Afin de créer son annonce de logement et de participer à un échange avec
    d’autres utilisateurs de la plateforme Wiplace,
    l’utilisateur est dans l’obligation de faire sa demande de membre vérifié.
    Cette procédure vise à contrôler l’identité ainsi que le titre de propriété ou
    de location du logement qu’il souhaite mettre à disposition.<br><br>

    L’utilisateur doit fournir une preuve de son identité en nous transmettant une photo recto / verso de sa carte
    d’identité ou de son passeport à la page photo. Il doit également fournir une preuve de la jouissance de son
    logement avec un justificatif de domicile de moins de 3 mois (facture d’électricité, feuille d’imposition, quittance
    de loyer) ainsi qu’une preuve de propriété ou de location (bail, titre de propriété).<br><br>

    Ces documents doivent être transmis via notre plateforme sous format png, jpeg ou pdf.<br><br>

    Wiplace se réserve le droit de refuser les pièces justificatives fournies par l’utilisateur si celles-ci sont
    inexactes, erronées, ou présentent une suspicion de fraude. L’utilisateur sera alors invité à fournir de nouvelles
    pièces. Le membre ne peut prétendre à la certification de membre vérifié s’il n’est pas en mesure de fournir les
    pièces demandées. Sans cette certification, il ne pourra aucunement accéder aux services réservés aux membres
    vérifiés.</p><br><br>

  <h4>L’abonnement</h4>
  <br>
  <p>
    L’abonnement est annuel et permet d’effectuer un nombre illimité d'échanges pendant une période de douze (12) mois à
    compter de la date de validation de l'abonnement. Les abonnements sont renouvelés automatiquement pour des périodes
    successives de 12 mois, à moins qu'ils ne soient annulés au plus tard le dernier jour du terme courant de 12 mois.
    En cas de résiliation de l’abonnement par le membre en cours d’année, aucun remboursement, même partiel, ne lui sera
    dû.<br><br>

    L’abonnement Wiplace constitue une offre composite liée à la prestation principale relevant du champ d’application
    de l’article 45 de la Directive 2006/112/CE. Ce service inclut la prestation de vérification préalable à l’accès aux
    échanges et des prestations de services accessoires telles que :</p>

  <ul>
    <!-- <li>Recevoir / Envoyer des messages via la messagerie Wiplace</li><br> -->
    <li>Effectuer / Accepter des demandes d’échanges</li><br>
    <li>Assistance en cas de litige</li><br>
    <li>Contrat d’échange </li><br>
    <li>Check In et Check Out</li><br>

    <!-- <li>Gestion du dépôt de garantie</li><br> -->
  </ul>

  <p>L’abonnement Wiplace constitue une prestation de services, nécessitant une intervention humaine, obligatoire et
    préalable à la conclusion du service qui ne relève pas d’un service électronique fourni par voie d’Internet et
    largement automatisé au sens de l’article 259 B 12° du Code général des impôts mais constitue un service entrant
    dans le champ d’application de l’article 45 de la Directive 2006/112/CE. </p>

  <br>
  <br>

  <h4>Prix </h4>

  <br>

  <p>Wiplace se réserve le droit de modifier ses prix à tout moment en publiant les nouveaux prix sur le site. Tous
    les prix incluent la TVA et / ou la taxe de vente, sauf indication contraire.</p>

  <br>
  <br>

  <h4>Paiements </h4>

  <br>

  <p>Tous les paiements doivent être effectués en vertu des présentes par carte de crédit ou de débit. Wiplace accepte
    les paiements par Visa, Visa Débit, Mastercard, Maestro, American Express et tout autre méthode de paiements locaux
    indiqués sur notre page de paiement. Tous les détenteurs de cartes de crédit / débit sont soumis à des contrôles de
    validation et d'autorisation par l'émetteur de la carte. En utilisant sa carte de crédit ou de débit, l’utilisateur
    confirme que la carte est la sienne et qu'il y a suffisamment de fonds ou de crédit disponible pour couvrir les
    frais. Wiplace ne stocke pas les informations de cartes bancaires et ne partage pas de détails financiers à une
    tierce personne.</p>

  <br>
  <br>

  <h4>Droit de rétractation </h4>

  <br>

  <p>Pour exercer son droit de rétractation concernant son abonnement, l’utilisateur doit informer Wiplace de sa
    décision en envoyant un mail en ce sens à l’adresse suivante : contactus@Wiplace.me.<br><br>

    A réception, Wiplace adressera à l’utilisateur un e-mail confirmant la prise en compte de l’exercice de son droit
    de rétractation.<br><br>

    Les sommes payées sont reversées au crédit de la carte bancaire utilisée par l’utilisateur dans les meilleurs
    délais, et, en tout état de cause, au plus tard dans les quatorze (14) jours suivant la date à laquelle le droit de
    rétractation a été exercé.<br><br>

    Le droit de rétractation s’appliquera à chaque renouvellement, et devra être exercé dans le délai de quatorze (14)
    jours à compter du premier jour du renouvellement automatique de l’abonnement.</p>

  <br>
  <br>

  <h4> Intégralité de l'accord </h4>

  <br>

  <p>Cet accord constitue l'intégralité de l'accord entre les parties sur l'objet des présentes. Il n'y a pas
    d'ententes, accords ou représentations, orales ou écrites, non spécifiés aux présentes en ce qui concerne le présent
    Accord. </p>
  <br>
  <br>
  <h4>Nous contacter</h4>
  <br>
  <p>Pour toute demande d’information ou pour une simple envie de discuter , contactez-nous !
    <br>
    Par email : contactus@Wiplace.me
    <br>
    Par courrier : 11 rue Charles Rossignol 91600 Savigny-Sur-Orge
  </p>
</div>
<app-footer></app-footer>
