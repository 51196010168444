import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MailService } from 'src/app/services/mail.service';

@Component({
  selector: 'app-section6',
  templateUrl: './section6.component.html',
  styleUrls: ['./section6.component.css']
})
export class Section6Component implements OnInit {

  siteKey: string = '6Lf6AzMdAAAAAKbBhmr_RO6pMRly_ssmoBqq58tb';

  postalCodePattern = '^[0-9]*';
  mailNotSent: boolean = false;

  bdeForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    schoolName: new FormControl('', [Validators.required]),
    educationType: new FormControl('1', [Validators.required]),
    bdeEmail: new FormControl('', [Validators.required, Validators.email]),
    zipCode: new FormControl('', [Validators.required, Validators.maxLength(5), Validators.pattern(this.postalCodePattern)]),
    city: new FormControl('', [Validators.required]),
    langKey: new FormControl('fr'),
    recaptcha: new FormControl('', Validators.required)
  });

  constructor(private mailService: MailService,
    private router: Router) { }

  ngOnInit() {
  }


  sendContactMail() {
    this.bdeForm.updateValueAndValidity();
    this.mailService.sendMail(this.bdeForm.value).subscribe(
      data => {
        this.mailNotSent = false;
        this.router.navigate(['mail-sent-success']);
        console.log("response", data);
      },
      error => {
        this.mailNotSent = true;
        console.log(error);
      }
    );
  }

}
