import { Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';



@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.css']
})
export class WelcomePageComponent implements OnInit {
  title = "Wiplace : Etudiants, voyagez en illimité toute l'année à moindre frais !"
  constructor(private metaTagService: Meta, private titleService: Title

  ) {

  }

  ngOnInit() {
    // this.titleService.setTitle(this.title);
    // this.metaTagService.addTags([
    //   { name: 'keywords', content: 'Etudiants, voyagez en illimité toute l année à moindre frais !' }, //a refaire!
    //   { name: 'x-ua-compatible', content: 'ie=edge' },
    //   { name: 'description', content: 'Trouvez facilement un appartement ou une maison en courte, moyenne ou longue durée pour vos séjours' },
    //   { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    //   { charset: 'UTF-8' }
    // ]);

  }
  toSection(section: string) {
    document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
  }


}
