<div class="row container-fluid navBar">

  <div class="col-md-2 col-sm-6 pt-1 d-flex justify-content-start">
    <a class="navItem" [routerLink]="'/'">
      <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />
    </a>
  </div>
  <div class="col-md-10 d-none d-md-block typography">
    <div class="row  pt-3 justify-content-end" style="margin-top: 7px;">
      <div class="col">
        <div><a class="navItem" [routerLink]="'/'" fragment="">Accueil</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/commentCaMarche'" fragment="" style="color: #0078E5">Comment
            ça marche ?</a>
        </div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/faq'" fragment="">FAQ - Besoin d’aide</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/confidenceSecurité'" fragment="">Confiance et sécurité</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main-div9 col-12">
  <h1 class="h1">Suis-je fait pour Wiplace   ? </h1> <br>
  <p>Vous avez plus de 18 ans, vous êtes propriétaire ou locataire d’un logement d’habitation assuré et vous avez envie
    de donner un sens à vos voyages, alors, it’s a match !
  </p> <br>

  <h4>Quels sont les logements éligibles ? </h4>
  <p>Maison, appartement, studio ou encore chambre dans une colocation, tous les logements disposant d’un espace privé,
    un coin pour la toilette et un autre pour le repas sont éligibles.
  </p><br>

  <h4>Où puis-je aller ? </h4>
  <p>Wiplace  a été créé en 2021. Comme c’est tout nouveau, les logements proposés sont situés dans les 4 coins de la
    France, mais bientôt, nous espérons vous emmener dans le monde entier.
  </p>

</div>
<app-create-request-section></app-create-request-section>
<app-footer></app-footer>
