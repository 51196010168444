<div class="main-div6 container-fluid">
  <div class="sub-div1 col-12">
    <h2>Devenir partenaire BDE</h2>
    <p>Parce que Wiplace a été créé avec et pour les étudiants, nous avons mis en place de nombreux partenariats avec
      des BDE et associations étudiantes. Vous êtes intéressés par un partenariat avec Wiplace  ? Remplissez le
      formulaire suivant, notre équipe reviendra vers vous dans les plus brefs délais : </p>
  </div>
  <div class="col-xl-12 col-lg-12 col-md-9 col-sm-9">
<div class="container">
  <div class="row">
    <div class="col">
      <img width="68" height="68" src="/assets/images/Rectangle 327 (1).png" alt="bde1">
    </div>
    <div class="col">
      <img width="68" height="68" src="/assets/images/Rectangle 328 (2).png" alt="bde6">
    </div>
    <div class="col">
      <img width="68" height="68" src="/assets/images/Rectangle 329 (2).png" alt="bde2">
    </div>
    <div class="col">
      <img width="68" height="68" src="/assets/images/Rectangle 330 (2).png" alt="bde3">
    </div>
    <div class="col">
      <img width="68" height="68" src="/assets/images/Rectangle 331 (1).png" alt="bde4">
    </div>
    <div class="col">
      <img width="130" height="68" src="/assets/images/Rectangle 332 (1).png" alt="bde5">
    </div>
  </div>
</div>
</div>
  <div class="card">
    <div class="row no-gutters">
      <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12">
        <img src="/assets/images/rectangle290.png" class="card-img img-fluid h-100" alt="image2">
      </div>

      <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12 sub-div2">
        <form [formGroup]="bdeForm">

          <div class="mb-3 form-group required">
            <label for="name" class="form-label">Prénom</label>
            <input type="text" class="form-control" id="name" formControlName="firstName" placeholder="Lilou">
            <div class="validationMsg"
              *ngIf="bdeForm.controls['firstName'].hasError('required') && (bdeForm.controls['firstName'].dirty || bdeForm.controls['firstName'].touched)">
              <small>Champ obligatoire</small>
            </div>
          </div>

          <div class="mb-3 form-group required">
            <label for="name" class="form-label">Nom</label>
            <input type="text" class="form-control" id="name" formControlName="lastName" placeholder="Dupont">
            <div class="validationMsg"
              *ngIf="bdeForm.controls['lastName'].hasError('required') && (bdeForm.controls['lastName'].dirty || bdeForm.controls['lastName'].touched)">
              <small>Champ obligatoire</small>
            </div>
          </div>

          <div class="mb-3 form-group required">
            <label for="typeEns" class="form-label">Enseignement</label>
            <select class="form-select" aria-label="Default select example" id="typeEns"
              formControlName="educationType">
              <option value="1">Choisir un type d'enseignement</option>
              <option value="école d'ingénieur">école d'ingénieur</option>
              <option value="école de commerce">école de commerce</option>
              <option value="université">université</option>
              <option value="autre">autre</option>
            </select>
            <div class="validationMsg"
              *ngIf="bdeForm.controls['educationType'].hasError('required') && (bdeForm.controls['educationType'].dirty || bdeForm.controls['educationType'].touched)">
              <small>Champ obligatoire</small>
            </div>
          </div>

          <div class="mb-3 form-group required">
            <label for="nameUni" class="form-label">Nom de l'établissement</label>
            <input type="text" class="form-control" id="nameUni" formControlName="schoolName"
              placeholder="Université d'Opale ">
            <div class="validationMsg"
              *ngIf="bdeForm.controls['schoolName'].hasError('required') && (bdeForm.controls['schoolName'].dirty || bdeForm.controls['schoolName'].touched)">
              <small>Champ obligatoire</small>
            </div>
          </div>

          <div class="mb-3 form-group required">
            <label for="town" class="form-label">Ville</label>
            <input type="text" class="form-control" id="town" formControlName="city" placeholder="ex: Toulouse">
            <div class="validationMsg"
              *ngIf="bdeForm.controls['city'].hasError('required') && (bdeForm.controls['city'].dirty || bdeForm.controls['city'].touched)">
              <small>Champ obligatoire</small>
            </div>
          </div>

          <div class="mb-3 form-group required">
            <label for="postalCode" class="form-label">Code postal</label>
            <input type="text" class="form-control" id="postalCode" formControlName="zipCode" placeholder="00000"
              onKeyPress="if(this.value.length==5) return false;">
            <div class="validationMsg" *ngIf="bdeForm.controls['zipCode'].dirty || bdeForm.controls['zipCode'].touched">
              <small *ngIf="bdeForm.controls['zipCode'].hasError('required')">Champ obligatoire</small>
              <small *ngIf="bdeForm.controls['zipCode'].hasError('maxlength')">ce champ ne doit pas dépasser 5
                caractères!</small>
              <small *ngIf="bdeForm.controls['zipCode'].hasError('pattern')">ce champ ne doit contenir que des
                chiffres!</small>
            </div>
          </div>

          <div class="mb-3 form-group required">
            <label for="mail" class="form-label">E-mail personnel</label>
            <input type="email" class="form-control" id="mail" formControlName="email" placeholder="exemple@email.com ">
            <div class="validationMsg" *ngIf="bdeForm.controls['email'].dirty || bdeForm.controls['email'].touched">
              <small *ngIf="bdeForm.controls['email'].hasError('required')">Champ obligatoire</small>
              <small *ngIf="bdeForm.controls['email'].hasError('email')">adresse mail invalide!</small>
            </div>
          </div>

          <div class="mb-3 form-group required">
            <label for="mailBde" class="form-label">E-mail du BDE</label>
            <input type="email" class="form-control" id="mailBde" formControlName="bdeEmail"
              placeholder="exemple@email.com ">
            <div class="validationMsg"
              *ngIf="bdeForm.controls['bdeEmail'].dirty || bdeForm.controls['bdeEmail'].touched">
              <small *ngIf="bdeForm.controls['bdeEmail'].hasError('required')">Champ obligatoire</small>
              <small *ngIf="bdeForm.controls['bdeEmail'].hasError('email')">adresse mail invalide!</small>
            </div>
          </div>


          <!--<div class="mb-2">
            <label for="nameBde" class="form-label">Nom du BDE</label>
            <input type="text" class="form-control" id="nameBde" formControlName="bdeName">
            <div class="validationMsg"
              *ngIf="bdeForm.controls['bdeName'].hasError('required') && (bdeForm.controls['bdeName'].dirty || bdeForm.controls['bdeName'].touched)">
              <small>Champ obligatoire</small>
            </div>
          </div>


          <div class="mb-2">
            <label for="addressBde" class="form-label">Adresse du BDE</label>
            <input type="text" class="form-control" id="addressBde" formControlName="bdeAddress">
            <div class="validationMsg"
              *ngIf="bdeForm.controls['bdeAddress'].hasError('required') && (bdeForm.controls['bdeAddress'].dirty || bdeForm.controls['bdeAddress'].touched)">
              <small>Champ obligatoire</small>
            </div>
          </div>-->

          <re-captcha formControlName="recaptcha" [siteKey]="siteKey"></re-captcha>

          <div class="col btn-position">
            <button type="submit" class="btn btn-primary mt-4" [disabled]="bdeForm.invalid"
              (click)="sendContactMail()"><span>Envoyer</span></button>
          </div>
        </form>

        <div *ngIf="mailNotSent" class="col-lg-9 btn-position">
          <div class="alert alert-danger  mt-3 alert-text" role="alert">
            Une erreur est survenue lors de l’envoi du formulaire veuillez réessayer.
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
