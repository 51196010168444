<div class="row container-fluid navBar">

  <div class="col-md-2 col-sm-6 pt-1 d-flex justify-content-start">
    <a class="navItem" [routerLink]="'/'">
      <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />
    </a>
  </div>
  <div class="col-md-10 d-none d-md-block typography">
    <div class="row  pt-3 justify-content-end" style="margin-top: 7px;">
      <div class="col">
        <div><a class="navItem" [routerLink]="'/'" fragment="">Accueil</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/commentCaMarche'" fragment="">Comment ça marche ?</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/faq'" fragment="" style="color: #0078E5">FAQ - Besoin
            d’aide</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/confidenceSecurité'" fragment="">Confiance et sécurité</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="main-div container-fluid">
  <div class="container">
    <h1 class="h1">Obtenez de l’aide</h1> <br>
    <h4 class="mt-3"> Besoin d’une assistance personnalisée ?  </h4>
    <p>Une question ? Un problème ? Des doutes au sujet d’une annonce ? Contactez-nous dès maintenant pour vous faire accompagner. </p>
    <div><button type="button" class="btn btn-primary"><a id="contact-btn" href="mailto:support@Wiplace.me">Nous
          contacter</a></button></div>
  </div>
  <br>
  <div class="container">
    <h1 class="h1">FAQ générale </h1>
    <h4 class="mt-3">A propos de <span id="colored-text" class="custom-color"> Wiplace</span> </h4> <br>
    <div class="accordion lining"
      data-responsive-accordion-tabs="accordion medium-accordion small-accordion large-accordion">
      <div class="accordion-item">
        <button class="accordion-button btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
          aria-expanded="true" aria-controls="collapseOne">
          Qu’est-ce que Wiplace  ?
        </button>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
          data-bs-parent="#accordionExample">
          <div class="accordion-body content-text">
            Wiplace est une plateforme de mise en relation entre les baroudeurs texte d’un jour et les voyageurs de toujours
            afin de s’échanger des logements gratuitement.
            <br> <br>
            A chacune de vos escapades , vous consacrez une grosse partie de votre budget au logement sur place. Mais
            pendant ce temps , votre résidence principale reste inhabitée . Au final c’est une double dépense qui pourrait
            être évitée . Comment ? En rejoignant notre communauté !
            <br> <br>
            Le principe est simple , je crée une annonce avec mon logement et je la publie sur Wiplace . Ensuite , je
            consulte les autres annonces de logements disponibles et j’entre en contact avec leurs propriétaires . Si
            nous sommes
            tout les deux séduits , nous définissons ensemble les dates du séjour et validons l’échange . Je n’ai plus
            qu’à
            laisser gracieusement mon habitation à la disposition de mon hôte qui me prête la sienne en retour.
            Ensemble ,
            nous participons à un système d’entraide et logeons chez l’un pendant que l’autre loge chez nous , le tout
            sans dépenser un sou . Ainsi , tout le monde y trouve son compte.
            <br> <br>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="accordion lining">
      <div class="accordion-item">
        <button class="accordion-button btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2"
          aria-expanded="true" aria-controls="collapse2">
          Qu’est-ce qu’un place-trotter ?
        </button>
        <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2"
          data-bs-parent="#accordionExample">
          <div class="accordion-body content-text">
            <strong><u> Place-trotter n.m·:</u></strong> Individu sympa et bienveillant qui loge gratuitement partout
            dans le monde en prêtant
            son habitation à d’autres place-trotters via Wiplace.
            <br> <br>
            Vous l’aurez compris, c’est le nom que nous donnons aux membres de notre communauté d'utilisateurs Wiplace
            dont le profil est vérifié . Comme ça, on fait tous partie d’une même communauté.
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="accordion lining">
      <div class="accordion-item">
        <button class="accordion-button btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3"
          aria-expanded="true" aria-controls="collapse3">
          Quels sont nos tarifs·?
        </button>
        <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3"
          data-bs-parent="#accordionExample">
          <div class="accordion-body content-text">
            Wiplace est un système gagnant – gagnant permettant à des particuliers de s’échanger leur habitations pour
            une durée limitée. Pendant que vous séjournez chez d’autres place-trotters, ces derniers logent chez vous,
            le tout gracieusement. C’est un échange de bons procédés où les échanges entre membres ne font pas l’objet de
            facturation et sont illimités.
            <br> <br>
            L’abonnement à la plateforme Wiplace est la seule chose à payer afin d’effectuer des échanges. Son prix
            est fixé à 60 euros par an et permet de profiter de tous nos services en illimité·:
            <br> <br>

            <ul>
              <li>Validation des échanges</li>
              <li>Mesures de sécurité</li>
              <li>Contrôle des identités et des logements</li>
              <li>Assistance en cas de litige</li>
            </ul>
            Afin de faciliter l’accès à Wiplace pour les étudiants, nous avons créé des partenariats avec des BDE des
            écoles et universités française. Chaque étudiant dont le BDE universitaire est partenaire de Wiplace est
            invité à se rapprocher de son BDE afin de bénéficier d’une réduction de 10 euros sur son abonnement.
            <br>
            Si vous constater au terme de votre abonnement que vous n’avez pas voyagé, votre abonnement vous est
            remboursé.
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="accordion lining">
      <div class="accordion-item">
        <button class="accordion-button btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4"
          aria-expanded="true" aria-controls="collapse4">
          Pourquoi et comment devenir partenaire BDE ?
        </button>
        <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4"
          data-bs-parent="#accordionExample">
          <div class="accordion-body content-text">
            Wiplace est une plateforme qui a été développée avec et pour les étudiants. Aujourd’hui, nous allons
            plus loin en proposant aux BDE de participer à l’aventure.
            <br> <br>
            En devenant partenaire, les étudiants du BDE participent à une expérience unique afin de co-créer
            Wiplace et optimiser son utilisation. Un plus pour le CV mais aussi pour le porte-monnaie, de quoi
            organiser la plus fabuleuse des soirées étudiantes ou un super événement festif.
            <br> <br>
            Les missions ? Nous donner vos retours sur la plateforme et faire découvrir notre application aux
            autres étudiants de votre école.
            <br> <br>

            Vous êtes membre d’un BDE et souhaitez plus d’information ?
            <u [routerLink]="['/']"
              fragment="commentCaMarche">Cliquez ici</u>

          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="accordion lining">
      <div class="accordion-item">
        <button class="accordion-button btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5"
          aria-expanded="true" aria-controls="collapse5">
          Comment faire un échange sur Wiplace ?
        </button>
        <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5"
          data-bs-parent="#accordionExample">
          <div class="accordion-body content-text">
            Pour faire un échange sur Wiplace, vous devez télécharger gratuitement l’application Wiplace sur
            votre smartphone via Google Play ou App Store.
            <br> <br>
            Après avoir ouvert votre application et créé un compte utilisateur, vous pouvez librement consulter
            les annonces disponibles.
            <br> <br>
            Afin de créer une demande d’échange, vous  devez créer l’annonce du logement que vous
            proposez à l’échange. Votre annonce doit être remplie avec soin et correspondre avec exactitude au
            logement qui sera laissé aux autres membre au cours de l’échange.
            <br> <br>
            Une fois l’annonce complétée et publiée, vous pouvez rentrer en contact avec les propriétaires des
            logements qui vous intéressent afin de leur poser vos questions. Lorsque vous avez choisi vos dates de
            séjour et que vous avez sélectionné une annonce, vous pouvez envoyer votre demande d’échange. Le
            place-trotter dispose alors d’un délai de 48 heures afin de vous donner son accord pour l’échange.
            <br> <br>

            <ul>
              <li>En cas de refus de la part du place-trotter ou sans réponse de sa part dans le délai
                imparti, la demande d’échange sera automatiquement annulée. Vous pourrez alors
                effectuer une nouvelle demande.</li>
              <li>En cas de retour positif, l’échange est validé et vous pouvez vous apprêter à partir.
                N’oubliez pas d’être bienveillant à l’égard de votre place-trotter et à lui laisser
                 un logement propre et accueillant.</li>
            </ul>

            <u>Attention :</u> Pour envoyer votre demande d’échange, vous devez souscrire à l’abonnement Wiplace.

          </div>
        </div>
      </div>
    </div>
  </div>
  <br> <br>
  <div class="container">
    <h4>Utilisation de <span id="colored-text" class="custom-color"> Wiplace</span> </h4> <br>
    <div class="accordion lining">
      <div class="accordion-item">
        <button class="accordion-button btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6"
          aria-expanded="true" aria-controls="collapse6">
          Comment devenir un place-trotter vérifié?
        </button>
        <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6"
          data-bs-parent="#accordionExample">
          <div class="accordion-body content-text">
            Devenir un place-trotter vérifié est obligatoire pour pouvoir réaliser un échange avec d’autres
            membres. Cela nous permet de garantir la sécurité de nos utilisateur et la légalité de l’échange.
            <br> <br>
            Pour devenir un place-trotter vérifié, vous devez nous fournir les informations suivantes :
            <br> <br>

            <ul>
              <li>Adresse e-mail</li>
              <li>Numéro de téléphone</li>
              <li>Identité</li>
              <li>Propriété du logement</li>
            </ul>

            Pour cela, connectez vous à votre compte sur votre application Wiplace puis rendez vous sur votre
            profil. Vous y trouverez un onglet intitulé « devenir membre vérifié ».
            <br> <br>
            Cliquez sur l’information que vous souhaitez fournir et suivez les étapes.
            <br> <br>
            Pour le contrôle de l’identité et du logement, vous devez nous fournir les documents suivants :
            <br> <br>
            <ul>
              <li>Vérification de l’identité : Carte d’identité, passeport à la page photo ou permis de conduire
                européen en cours de validité.</li>
              <li>Vérification de logement : Titre de propriété, bail, état des lieux d’entrée, facture d’électricité
                ou justificatif de versement APL de moins de 3 mois.</li>
            </ul>
            Vos documents seront vérifiés par notre équipe en interne dans un délai maximum de 48 heures.
            <br> <br>
            Les documents doivent être lisibles et clairs. Tout document non conforme, illisible ou faisant l’objet
            d’une suspicion de fraude sera refusé.
            <br> <br>
            Pour transmettre des documents, plusieurs choix existent:
            <br> <br>
            <ul>
              <li>Appareil photo si vous souhaitez prendre une photo depuis l’application.</li>
              <li>Galerie si vous souhaitez rechercher dans les photos.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <br>

    <div class="accordion lining">
      <div class="accordion-item">
        <button class="accordion-button btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7"
          aria-expanded="true" aria-controls="collapse7">
          Comment créer une annonce ?
        </button>
        <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7"
          data-bs-parent="#accordionExample">
          <div class="accordion-body content-text">
            Pour créer une annonce, vous devez télécharger l’application Wiplace sur votre smartphone et
            créer un compte. Une fois connecté à votre compte, vous pouvez créer votre annonce en vous
            rendant sur votre profil et en cliquant sur le bouton « créer une annonce de logement ».
            <br> <br>
            Vous serez alors guidé étape par étape pour créer votre annonce et devrez fournir les informations
            suivantes :
            <br> <br>

            <ul>
              <li>Localisation</li>
              <li>Superficie</li>
              <li>Type d’habitation</li>
              <li>Espaces et commodités</li>
              <li>Règle à respecter</li>
              <li>Description du logement</li>
              <li>Description du quartier</li>
              <li>Photos du logement</li>
            </ul>

            Vous pouvez quitter la création d’une annonce à tout moment et la sauvegarder si vous souhaitez la
            reprendre plus tard. L’annonce sera mise en ligne et visible par les autres utilisateurs uniquement
            lorsqu’elle sera complète.
            <br> <br>
            L’annonce doit être conforme au logement qui sera laissé en échange à d’autres utilisateur. Elle doit
            être remplie avec soin et exactitude.
            <br> <br>
            Les annonces incomplètes ou mal complétées seront supprimés.
          </div>
        </div>
      </div>
    </div>

    <br>

    <div class="accordion lining">
      <div class="accordion-item">
        <button class="accordion-button btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8"
          aria-expanded="true" aria-controls="collapse8">
          Comment procéder au paiement d’un abonnement ?
        </button>
        <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading8"
          data-bs-parent="#accordionExample">
          <div class="accordion-body content-text">
            Pour payer l’abonnement à Wiplace, vous devez télécharger l’application Wiplace sur votre
            smartphone et créer un compte. Une fois connecté à votre compte, vous pouvez créer votre
            abonnement en vous rendant sur votre profil et en cliquant sur le bouton « Abonnement».
            <br> <br>
            Pour régler votre abonnement, utilisez une carte bancaire à votre nom en cours de validité.
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="accordion lining">
      <div class="accordion-item">
        <button class="accordion-button btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9"
          aria-expanded="true" aria-controls="collapse9">
          Comment faire une affiliation à un BDE ?
        </button>
        <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading9"
          data-bs-parent="#accordionExample">
          <div class="accordion-body content-text">
            Pour affilier votre compte à un de nos BDE partenaires, vous devez vous rendre sur votre profil et
            rechercher l’onglet “Affiliation BDE”. Vous pourrez alors saisir le code qui vous aura été remis par le
            BDE et serait automatiquement rattaché à ce dernier. En étant affilié au BDE, vous pouvez profiter
            d’une remise de 10 euros sur votre abonnement.
            <br> <br>
            Si vous ne savez pas comment obtenir votre code BDE, vous pouvez vous rapprocher du BDE de votre
            école qui vous fournira son code. Il est possible que le BDE ne soit pas encore partenaire de Wiplace.
            Dans ce cas, il ne sera pas possible de vous rattacher à un BDE.
          </div>
        </div>
      </div>
    </div>

    <br>

    <div class="accordion lining">
      <div class="accordion-item">
        <button class="accordion-button btn-text" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10"
          aria-expanded="true" aria-controls="collapse10">
          Comment faire la remise des clés ?
        </button>
        <div id="collapse10" class="accordion-collapse collapse" aria-labelledby="heading10"
          data-bs-parent="#accordionExample">
          <div class="accordion-body content-text">
            Au moment de valider votre échange, vous êtes invités à renseigner le type de remise des clés que
            vous avez choisi.
            <br> <br>
            Vous pouvez choisir d’effectuer la remise des clés par vos propres moyens. Nous vous conseillons les
            options suivantes :
            <br> <br>
            <ul>
              <li>Remise des clés par un proche ou une tierce personne.</li>
              <li>Installation d’une boite à code proche du logement donc vous fournirez la combinaison à l’hôte
                un jour avant l’échange.</li>
              <li>Envoie par courrier postal.</li>
            </ul>
            A noter qu’il est de votre responsabilité de fournir les clés permettant d’accéder au logement à votre
            hôte. Wiplace ne peut intervenir si vous avez décidé de vous charger de la remise des clés.
            <br> <br>
            Une autre option est possible et incluse dans notre abonnement sans frais supplémentaire : la remise
            des clés par le BDE. En choisissant cette option, vous pourrez sélectionner un BDE partenaire Wiplace
            de l’université ou l’école de votre choix et déposer la clé au référent du BDE qui vous sera attribué. Ce
            dernier se chargera de remettre vos clés au place-trotter au moment de son arrivée, de les récupérer
            avant le départ et enfin de vous les remettre au moment de votre retour.
            <br> <br>
            Un suivi de la remise des clés est assuré par notre plateforme. Vous pouvez consulter à tout moment
            le statut de la remise des clés via l’application.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
