<div class="main-div1 container-fluid row">
  <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center" style="margin: auto;">
    <div>
      <h1>Partagez, voyagez,</h1>
      <h1 style="color: #0078E5;">économisez</h1>
      <p><b>Wiplace </b> vous propose d’échanger votre appartement ou maison avec d’autres utilisateurs pendant vos
        séjours.
        Découvrez notre système gagnant-gagnant pour profiter au maximum de vos vacances.
      </p>
      <div class="sub-div">
        <a href="https://bit.ly/3JHp0Gs" target="_blank" id="dwn">
          <img width="100%" height="54" src="/assets/images/googlePlay.svg" alt="image" />
        </a>
        <a href="https://apple.co/3rVG2tX" target="_blank" id="dwnld">
          <img width="100%" height="54" src="/assets/images/appStore.svg" alt="image" />

        </a>
      </div>

    </div>
  </div>

  <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 " style="text-align: center;">
    <iframe class="video" src="https://www.youtube.com/embed/bX7qmm1PFlI?autoplay=1&mute=1"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

  </div>

</div>
