<div class="container-fluid main-div10">
  <h1 class="mb-5 h1" id="title1">Comment ça fonctionne ? </h1>

  <div class="col" id="sec0">

    <div class="sub-div1 row gx-5">
      <div class="col-lg-3 col-md-12 d-flex justify-content-center align-items-center">
        <div class="sub-div2">
          <img src="/assets/images/howItWorks1.svg" alt="image" />
        </div>
      </div>
      <div class="col-lg-6 col-md-12 desc">
        <h4>Créez votre annonce </h4>
        <p>Ajoutez des photos et une description de votre cocon </p>
      </div>
    </div>

    <div id="sec2" class="sub-div1 row flex-column-reverse flex-md-row">
      <div class="col-lg-5 col-md-12 desc">
        <h4>Recherchez des logements </h4>
        <p>Utilisez le filtre et / ou le calendrier pour effectuer votre recherche </p>
      </div>
      <div class="col-lg-3 col-md-12 d-flex justify-content-center align-items-center">
        <div class="sub-div2">
          <img src="/assets/images/Group 417.png" alt="image" width="200px" />
        </div>
      </div>
    </div>

    <div class="sub-div1 row">
      <div class="col-lg-3 col-md-12 d-flex justify-content-center align-items-center">
        <div class="sub-div2 ">
          <img src="/assets/images/Group 423-.png" alt="image" width="200px" />
        </div>

      </div>
      <div class="col-lg-6 col-md-12 desc">
        <h4>Repérez les annonces qui vous plaisent </h4>
        <p>Ajoutez-les à vos favoris pour les retrouver facilement </p>
      </div>
    </div>

    <div class="sub-div1 row flex-column-reverse flex-md-row">
      <div class="col-lg-5 desc">
        <h4>Contactez d’autres place-trotters </h4>
        <p>Découvrez vos potentiels hôtes et posez-leur vos questions </p>
      </div>
      <div class="col-lg-3 d-flex justify-content-center align-items-center">
        <div class="sub-div2 ">
          <img src="/assets/images/Group 424--.png" alt="image" width="200px"/>
        </div>
      </div>
    </div>

    <div class="sub-div1 row">
      <div class="col-lg-3 d-flex justify-content-center align-items-center">
        <div class="sub-div2 ">
          <img src="/assets/images/howItWorks5.svg" alt="image" />
        </div>
      </div>
      <div class="col-lg-6 desc">
        <h4>Effectuez votre demande d’échange </h4>
        <p>Et attendez la validation par votre hôte </p>
      </div>
    </div>

    <div class="sub-div1 row flex-column-reverse flex-md-row">
      <div class="col-lg-5 col-lg-push-3 desc">
        <h4>Partez l’esprit léger </h4>
        <p>Restez zen, votre habitation est entre de bonnes mains </p>
      </div>
      <div class="col-lg-3 col-lg-pull-5 d-flex justify-content-center align-items-center">
        <div class="sub-div2">
          <img src="/assets/images/Group 476-.png" alt="image" width="190px" />
        </div>
      </div>
    </div>

  </div>
</div>
