import { HttpClient,HttpHeaders,HttpClientModule  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateExcelFileService {

  endpointUrl = environment.apiUrl+'public/emailaddress';

  constructor(private http: HttpClient) { }

  saveEmail(email: any) {
    // const headers = {
    //   // 'Access-Control-Allow-Origin' : '*',
    //   // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    //   // 'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTYzNTk1MzY1Mn0.ErQXJZbX7ozj3WMW2jUpVqj-kTY7yR5slJsd95ZlBSW5Q1Vlf64R4j_nxLP2lzC_un8Nq6DvfUhyxygXlQ_Wpg',
    //   // 'Content-Type': 'application/json',
    //   // 'accept': '*/*'
    // };
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTYzNTk1MzY1Mn0.ErQXJZbX7ozj3WMW2jUpVqj-kTY7yR5slJsd95ZlBSW5Q1Vlf64R4j_nxLP2lzC_un8Nq6DvfUhyxygXlQ_Wpg',
      'Content-Type': 'application/json',
      'accept': '*/*'
    })
    // headers.set('content-type', 'application/json')
    // headers.set('Accept', 'application/json')
    // headers.set('Access-Control-Allow-Origin', 'https://admin.nextplace.me/api/')
    // headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept')
    // headers.set('Authorization', 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTYzNTk1MzY1Mn0.ErQXJZbX7ozj3WMW2jUpVqj-kTY7yR5slJsd95ZlBSW5Q1Vlf64R4j_nxLP2lzC_un8Nq6DvfUhyxygXlQ_Wpg')
    let options = { headers: headers };

        console.log(options);
    return this.http.post(this.endpointUrl, email, options);

  }

}
