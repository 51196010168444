<div class="row container-fluid main-div1">

    <div class="col-md-2 col-sm-6 pt-1 d-flex justify-content-start">
        <a [routerLink]="'/'">
          <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />
        </a>
    </div>
    <div class="col-md-10 d-none d-md-block typography">
        <div class="row  pt-3 justify-content-end" style="margin-top: 7px;">
            <div class="col">
                <div><a [routerLink]="'/'" fragment="">Accueil</a></div>
            </div>
            <div class="col">
                <div><a [routerLink]="'/commentCaMarche'" fragment="">Comment ça marche ?</a></div>
            </div>
            <div class="col">
                <div><a [routerLink]="'/faq'" fragment="">FAQ - Besoin d’aide</a></div>
            </div>
            <div class="col">
                <div><a [routerLink]="'/confidenceSecurité'" fragment="">Confiance et sécurité</a></div>
            </div>
        </div>
    </div>
</div>
