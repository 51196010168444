<div class="row container-fluid navBar">

    <div class="col-md-2 col-sm-6 pt-1 d-flex justify-content-start">
        <a class="navItem" [routerLink]="'/'">
          <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />
        </a>
    </div>

    <div class="col-md-10 d-none d-md-block typography">
        <div class="row  pt-3 justify-content-end" style="margin-top: 7px;">
            <div class="col">
                <div><a class="navItem" [routerLink]="'/'" fragment="" [routerLinkActive]="['active']">Accueil</a>
                </div>
            </div>
            <div class="col">
                <div><a class="navItem" [routerLink]="'/conditions'" [routerLinkActive]="['active']"
                        fragment="">Condition générales d’utilisation</a></div>
            </div>
            <div class="col">
                <div><a class="navItem" [routerLink]="'/conditionsGénérales'" [routerLinkActive]="['active']"
                        fragment="">Conditions générales de vente</a></div>
            </div>
            <div class="col">
                <div><a class="navItem" [routerLink]="'/charte'" [routerLinkActive]="['active']" fragment="">Charte
                        de bonne conduite</a></div>
            </div>
            <div class="col">
                <div><a class="navItem" [routerLink]="'/confidentialityChart'" [routerLinkActive]="['active']"
                        fragment="" style="color: #0078E5">Charte de
                        confidentialité</a></div>
            </div>
            <!--<div class="col p-2 dropdown">
          <button class="btn typography dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false">
            Français
          </button>
          <ul class="dropdown-menu typography" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item">Français</a></li>
            <li><a class="dropdown-item">English</a></li>
          </ul>
        </div>-->

        </div>
    </div>
</div>

<div class="content">
    <h1 class="h1">Charte de confidentialité </h1>
    <p>
        La présente Charte de confidencialité (ci-après « Charte ») est celle de la société Wiplace qui édite le site
        internet et l’application Wiplace (ci-après désignée comme « nous ») :
        <br><br>
        En sa qualité de responsable du traitement, Wiplace attache une grande importance à la protection de vos
        données personnelles et au respect de votre vie privée.
        <br><br>
        Cette présente Charte a pour objectif de vous informer de nos pratiques en ce qui concerne la collecte,
        l’utilisation et le partage des informations que vous pouvez être amenés à nous fournir lorsque vous utilisez
        les services de Wiplace.
        <br><br>
        Nous vous invitons à lire attentivement cette Charte, qui s’applique lorsque vous utilisez Wiplace, afin de
        connaître et comprendre nos pratiques en ce qui regarde les traitements de vos données personnelles que nous
        opérons ainsi que vos droits.
        <br><br>
        Cette Charte est susceptible d’évolutions à tout moment ; en cas d’évolutions substantielles, nous vous en
        informerons dans un délai raisonnable et, le cas échéant, demanderons à nouveau votre consentement, avant son
        entrée en vigueur.
    </p>
    <br><br>
    <h2>Sommaire</h2>
    <ol class="colorBlue">
        <li (click)="toSection('sectionNumber1')" routerLinkActive="active">
            Objectif de la Charte
        </li>
        <br>
        <li (click)="toSection('sectionNumber2')" routerLinkActive="active">
            Les informations que nous collectons
            <ol>
                <li>2.1. Les informations obligatoires que vous nous transmettez directement</li>
                <li>2.2. informations supplémentaires et facultatives que vous pouvez nous transmettre</li>
                <li>2.3. informations que nous collectons automatiquement</li>
            </ol>
        </li>
        <br>
        <li (click)="toSection('sectionNumber3')" routerLinkActive="active">L’utilisation des données que nous
            collectons</li><br>
        <li (click)="toSection('sectionNumber4')" routerLinkActive="active">Durées de conservation de vos données</li>
        <br>
        <li (click)="toSection('sectionNumber5')" routerLinkActive="active">Les destinataires de nos données</li><br>
        <li (click)="toSection('sectionNumber6')" routerLinkActive="active">Le stockage de vos informations</li><br>
        <li (click)="toSection('sectionNumber7')" routerLinkActive="active">Le transfert de vos données</li><br>
        <li (click)="toSection('sectionNumber8')" routerLinkActive="active">Notre utilisation des cookies
            <ol>
                <li>8.1. Cookies techniques</li>
                <li>8.2. Cookies de mesure d’audience</li>
                <li>8.3. Cookies réseaux sociaux / tiers</li>
            </ol>
        </li><br>
        <li (click)="toSection('sectionNumber9')" routerLinkActive="active">Comment puis-je m’opposer à l’utilisation
            des cookies ?</li><br>
        <li (click)="toSection('sectionNumber10')" routerLinkActive="active">Vos droits</li><br>
        <li (click)="toSection('sectionNumber11')" routerLinkActive="active">Exercer vos droits</li><br>
    </ol>
    <br>
    <h3 id="sectionNumber1">1. Objectif de la Charte </h3>
    <br>
    <p>
        Afin de vous permettre d’utiliser au mieux nos service sur Wiplace, nous pouvons collecter et utiliser
        certaines de vos informations. Cette Charte vise à vous informer de nos pratiques et des raisons de celles-ci ,
        afin de garantir toute transparence avec nos utilisateurs.
        <br><br>
        Nous nous engageons à utiliser vos informations
        dans le respect des lois relatives à la protection des données personnelles.
    </p>
    <br><br>
    <h3 id="sectionNumber2">2. Les informations que nous collectons</h3>
    <br>
    <p>Nous sommes amenés à recueillir et traiter les données suivantes : </p>
    <h4>2.1. Les informations obligatoires que vous nous transmettez directement</h4>
    <p>
        En fonction de l’utilisation de nos services sur Wiplace, certaines informations doivent obligatoirement être
        fournies, ce sont les données que vous nous transmettez directement.
        <br><br>
        Ces informations doivent obligatoirement être fournies, par exemple pour la création de votre compte ou pour
        établir la communication entre les invités de votre logement et vous-même. Ces données sont nécessaires afin que
        nous puissions vous fournir le service concerné.
        <br><br>
        En ce sens, nous vous expliquons pourquoi nous en avons besoin.
    </p>
    <ul>
        <li>
            <p>
                <a class="boldy">2.1.1 Informations concernant votre identité et vos coordonnées,</a> telles que votre
                nom,
                prénom,
                email et mot de
                passe. Ces informations sont nécessaires lors de la création de votre compte sur Wiplace.
                <br>
            </p>
        </li>
        <li>
            <p>
                <a class="boldy">2.1.2 Informations concernant votre logement,</a> telles que la nature de votre
                logement (appartement ou maison), sa localisation, sa surface, son nombre de chambres et de salles de
                bain, son nombre de couchages, ses
                équipements et au moins une photographie de votre logement. Ces informations sont nécessaires pour
                l’ajouter sur
                Wiplace.
            </p>
        </li>
        <li>
            <p>
                <a class="boldy">2.1.3 Informations concernant votre calendrier,</a> telles que les dates et créneaux de
                disponibilité de votre logement. Ces informations sont nécessaires lorsque vous souhaitez partager votre
                logement avec la communauté Wiplace.
            </p>
        </li>
        <li>
            <p>
                <a class="boldy">2.1.4 Coordonnées bancaires,</a> telles que le numéro de votre carte de crédit,
                cryptogramme visuel, date de validité. Ces informations sont nécessaires lorsque vous payez en ligne un
                service. Ces données, à l’exception de la date de validité, sont collectées et traitées directement par
                notre prestataire de paiement et nous ne les conservons jamais. Nous conservons la date de validité afin
                de pouvoir vous prévenir lorsqu’elle arrive à expiration et doit donc être renouvelée.
            </p>
        </li>
        <li>
            <p>
                <a class="boldy">2.1.5 Justificatifs nécessaires pour les garanties,</a> tels que les justificatifs de
                l’annulation ou du dommage que vous avez subi.
            </p>
        </li>
    </ul>
    <h4>2.2. Les informations supplémentaires et facultatives que vous pouvez nous transmettre</h4>
    <p>
        Lors de l’utilisation de nos services sur Wiplace, afin de bénéficier d’une expérience utilisateur enrichie,
        vous pouvez être amené à nous fournir des catégories d’informations supplémentaires, dont le renseignement est
        facultatif, telles que les suivantes :
    </p>
    <ul>
        <li>
            <p>
                <a class="boldy">2.2.1 Informations supplémentaires enrichissant votre profil,</a> telles que votre
                photographie, votre date de naissance, votre numéro de téléphone, la description que vous faites de vous
                sur votre profil, les langues parlées, certaines de vos préférences, les différents réseaux sociaux sur
                lesquels vous êtes inscrits.
            </p>
        </li>
        <li>
            <p>
                <a class="boldy">2.2.2 Informations supplémentaires enrichissant votre logement,</a> telles que des
                photographies de votre logement, une description que vous faites de votre logement, de votre quartier.
            </p>
        </li>
        <li>
            <p>
                <a class="boldy">2.2.3 Justificatifs d’identité,</a>
                tels qu’une copie de votre carte d’identité, permis de conduire, de votre passeport, un justificatif du
                logement que vous avez ajouté sur Wiplace. Ces informations peuvent nous permettre de vérifier que les
                informations fournies sont exactes afin de rassurer la communauté Wiplace et de protéger nos membres
                de la fraude.
            </p>
        </li>
        <li>
            <p>
                <a class="boldy">2.2.4 Publications, liste d’amis et autres interactions sociales,</a>
                tels que les groupes et clubs  que vous créez ou rejoignez, les amis que vous ajoutez, les
                commentaires que vous rédigez et les notations que vous laissez.

            </p>
        </li>
        <li>
            <p>
                <a class="boldy">2.2.5 Messagerie privée,</a>
                Nous collectons également les messages privés que vous échangez avec d’autres membres, mais nous les
                traitons comme de la correspondance privée confidentielle conformément à la réglementation en vigueur.
            </p>
        </li>
    </ul>
    <h4>2.3. Les informations que nous collectons automatiquement</h4>
    <p>
        Des données peuvent être collectées automatiquement, nous collectons de façon automatique certaines
        catégories d’informations telles que :
    </p>
    <ul>
        <li>
            <p>
                <a class="boldy">2.3.1 Informations découlant de votre usage de nos services Wiplace,</a>
                tels que les pages que vous consultez, les logements que vous ajoutez à vos favoris, les recherches que
                vous sauvegardez, les termes de vos recherches, les échanges de logement que vous réalisez, votre taux
                et temps de réponse aux questions qui vous sont posés par d’autres membres, votre date de dernière
                connexion.
            </p>
        </li>
        <li>
            <p>
                <a class="boldy">2.3.2 Informations concernant votre équipement</a>
                pour accéder à Wiplace, telles que votre navigateur internet, la résolution de votre écran, votre
                version de système d’exploitation, votre adresse IP.
            </p>
        </li>
        <li>
            <p>
                <a class="boldy">2.3.3 Journaux de connexion</a>
                qui correspondent aux fichiers logs de nos serveurs. Les informations contenues peuvent être votre
                adresse IP, le type de navigateur utilisé, votre système d’exploitation, les pages consultées, les pages
                de provenance.
            </p>
        </li>
        <li>
            <p>
                <a class="boldy">2.3.4 Cookies.</a>
                Nous utilisons également des cookies qui nous permettent de collecter automatiquement certaines
                informations.
            </p>
        </li>
    </ul>
    <br><br>
    <h3 id="sectionNumber3">3. L’utilisation des données que nous collectons </h3>
    <br>
    <p>Nous utilisons les données que nous recueillons pour les raisons exposées ci-après : </p>
    <br><br>
    <table class="table">
        <tr>
            <td>
                Finalités
            </td>
            <td>Fondement légal </td>
        </tr>
        <tr>
            <td>
                <a class="boldy">3.1 exécuter les contrats conclus entre utilisateursou et vous fournir les services
                    demandés</a>
                <br><br>
                Par exemple :
                <br>
                <br>
                - Vérification : nous avons besoin de votre justificatif d’identité et d’un justificatif de domicile
                afin de vous donner le statut de membre vérifié
                <br>
                - Garanties : en cas d’incident, afin de vous aider, nous avons besoin de connaitre tout
                justificatif
                lié à la constitution d’un dossier, qui doit être conservé jusqu’à résolution du dossier (photos,
                justificatif médical, devis de réparation et/ou remplacement, etc.)

            </td>
            <td>Ce traitement est nécessaire à l’exécution de nos obligations contractuelles respectives, conformément à
                nos CGU, acceptées lors de la création de votre compte et de l’utilisation de Wiplace.</td>
        </tr>
        <tr>
            <td><a class="boldy">3.2 vous permettre de personnaliser votre profil afin de partager davantage
                    d’informations avec la communauté Wiplace, sociabiliser et rassurer les autres membres de
                    Wiplace qui souhaiteraient vous accueillir dans leur logement ou être accueilli chez vous. </a>
                <br><br>
                Par exemple :
                <br><br>
                - La description que vous faites de vous, les langues que vous indiquez parler, la photographie ajoutée
                à votre compte
            </td>
            <td>Ce traitement est réalisé sur la base de votre consentement.</td>
        </tr>
        <tr>
            <td><a class="boldy">3.3 vérifier votre identité et certifier votre compte membre afin de bénéficier de la
                    confiance des autres membres de la communauté Wiplace</a>
            </td>
            <td>Ce traitement est réalisé sur la base de votre consentement.</td>
        </tr>
        <tr>
            <td><a class="boldy">3.4 répondre à vos questions et vous assister dans vos demandes</a>
                <br><br>
                Par exemple : 
                <br><br>
                - Demandes sur la façon d’utiliser nos services<br>
                - Nous informer d’un litige avec un autre membre<br>
                - Effectuer une réclamation ou une demande d'exercice de vos droits
            </td>
            <td>Ce traitement est nécessaire à l’exécution de nos obligations contractuelles respectives.</td>
        </tr>

        <tr>
            <td><a class="boldy">3.5 vous envoyer des lettres d’information et des notifications concernant les
                    actualités de la communauté Wiplace et vous prévenir lorsqu’une activité se produit sur votre
                    compte
                </a>
                <br><br>
                Par exemple :
                <br><br>
                - Un membre a répondu à vos messages<br>
                - Un membre ajoute votre maison à ses favoris<br>
            </td>
            <td>Ce traitement est réalisé sur la base de votre consentement.
                Vous pouvez vous y opposer et nous demander de ne plus recevoir ces emails, en vous
                désinscrivant à l’aide du lien situé en pied de page de nos emails. </td>
        </tr>

        <tr>
            <td><a class="boldy">3.6 vous envoyer des emails et messages marketing </a>
            </td>
            <td>Ce traitement est réalisé sur la base de votre consentement. </td>
        </tr>
        <tr>
            <td><a class="boldy">3.7 prévenir la fraude et les activités illicites</a><br>
                Nous avons un intérêt légitime à vérifier certains comportements suspects d’utilisateurs sur Wiplace
                et a procéder à des vérifications d’informations, afin de lutter contre la fraude (usurpation
                d’identité) les abus (création de faux comptes) et toute activité illicite (piratage) ou contraire à
                l’ordre public. Nous considérons que vous pouvez raisonnablement vous attendre à ce que nous effectuons
                ces opérations et qu’elles ne nuisent pas à vos droits et libertés. <br>
                Nous sommes susceptibles de mettre en place des systèmes automatisés de détection et de vérifier
                humainement la pertinence des suspicions que nos systèmes auront détectées.
            </td>
            <td>Ce traitement est nécessaire afin de préserver la sécurité des membres de Wiplace et les protéger des tentatives
                de fraude , ce qui constitue un intérêt légitime </td>
        </tr>
        <tr>
            <td><a class="boldy">3.8 analyser l’utilisation de nos services</a><br>
                Nous analysons le comportement des visiteurs de notre site internet (visites sur les pages,
                utilisation des fonctionnalités, termes recherchés) et sur notre application afin de le faire évoluer,
                d’améliorer la pertinence des suggestions de recherches, de modifier nos interfaces et nos parcours
                d’utilisation lorsque nous détectons qu’ils ne sont pas assez intuitifs pour les visiteurs. <br>
                Par ailleurs, nous envoyons de manière régulière à tout ou partie de nos membres des questionnaires afin
                de mieux comprendre leur utilisation du site et de l’application et nous aider à les améliorer. La
                participation à ces études est facultative, et nous précisons à chaque fois l’objectif de l’étude, ainsi
                que le traitement qui sera fait des données collectées.
            </td>
            <td>Ce traitement est nécessaire afin de vous fournir un meilleur service et permettre à nos équipes de
                rendre notre site et application plus attractif afin de faciliter votre navigation ce qui constitue un
                intérêt légitime. </td>
        </tr>
        <tr>
            <td><a class="boldy">3.9 percevoir vos paiements ou vous transmettre les sommes collectées pour votre
                    compte</a>
            </td>
            <td>Ce traitement est nécessaire à l’exécution de nos obligations contractuelles respectives.</td>
        </tr>
        <tr>
            <td><a class="boldy">3.10 conserver des journaux de connexion</a>
                <br><br>
                Par exemple :
                <br><br>
                - adresse IP <br>
                - type de navigateur utilisé <br>
                - système d’exploitation <br>
                - pages consultées <br>
                - pages de provenance, les heures <br>
                - dates de visite sur le site et l’application
            </td>
            <td>Ce traitement est fondé sur une obligation légale de conserver pendant une durée d’un an les journaux de
                connexion à Wiplace</td>
        </tr>
        <tr>
            <td><a class="boldy">3.11 exécuter des opérations de profilage </a><br>

                Le profilage consiste par exemple à évaluer des aspects personnels, prédire un comportement ou des
                préférences. <br>
                Nous effectuons plusieurs types de profilage : un à des fins de recommandation des logements qui
                pourraient potentiellement plaire à nos utilisateurs, un autre afin d’améliorer l’ordre des logements
                apparaissant dans notre moteur de recherche, et enfin nous calculons une notion de similarité entre les
                logements
            </td>
            <td>Ce traitement est fondé sur l’intérêt légitime de renforcer et personnaliser l’offre de service en
                fonction de vos centres d’intérêts.</td>
        </tr>
        <tr>
            <td><a class="boldy">3.12 participer à des études et recherches</a><br>
                Du fait de notre positionnement et de notre activité, nous participons à des études et recherches
                universitaires, par exemple sur l’économie collaborative. Toutes vos données sont anonymisées dans le
                cadre de ces études, et utilisées à but uniquement statistique.
            </td>
            <td>Ce traitement est anonymisé</td>
        </tr>
        <tr>
            <td><a class="boldy">3.13 partager vos photos dans nos supports</a><br>

                Nous sommes susceptibles de reprendre certaines photos de logement afin de les utiliser dans les
                supports de communication de l’entreprise (newsletter, blog, publicité…). Ces photos sont anonymes, ne
                révèlent aucun élément de vie privée et sont prises dans le respect du droit à l’image.
            </td>
            <td>Vous pouvez bien évidemment nous demander de ne plus utiliser vos informations médias si vous ne
                souhaitez pas les partager. </td>
        </tr>

    </table>
    <br><br><br>
    <p>Pour les traitements réalisés sur la base de votre consentement, vous pouvez retirer à tout moment votre
        consentement en retirant vous-mêmes les informations de votre compte.
    </p>
    <br><br>
    <h3 id="sectionNumber4">4. Durées de conservation de vos données </h3>
    <br>
    <p>La durée de conservation de vos informations dépend de la nature des données et des finalités pour lesquelles
        nous avons eu besoin de les collecter.
        <br><br>
        De façon générale, nous nous engageons à maintenir les informations contenues dans votre profil pendant une
        durée de 3 ans à compter de notre dernier contact avec vous (par exemple votre dernière connexion sur
        Wiplace). Au-delà de ce délai de 3 ans, nous supprimerons automatiquement votre compte et toutes les données
        qui y sont associées.
        <br><br>
        Néanmoins :
    </p>
    <ul>
        <li>
            pour des obligations légales et comptables , nous pouvons conserver certaines informations pendant une durée
            maximale de 10 ans à compter de leur date (délai de prescription commercial) ;
        </li>
        <li>
            pour des raisons probatoires : les informations collectées à des fins de gestion et d’assistance technique
            pourront être conservées pendant une durée maximale de 5 ans à compter de leur date (délai de prescription
            civile) ;
        </li>
    </ul>
    <p>
        De façon plus spécifique, lorsque vous exercez vos droits reconnus par la réglementation sur la protection des
        données personnelles, nous pouvons conserver une copie de vos pièces d’identité pendant une durée maximale d’un
        an pour vos demandes de droit d’accès et de rectification et un délai de trois ans pour les demandes de droit
        d’opposition.

    </p>
    <br><br>
    <h3 id="sectionNumber5">5. Les destinataires de nos données</h3>
    <br>
    <p>
        <a class="boldy">Aux membres de Wiplace avec qui vous réalisez des échanges
        </a>
        <br><br>
        Lorsque vous réalisez un échange de logement avec un autre membre de Wiplace, vous allez
        mutuellement recevoir des informations de l’autre : il s’agit d’informations relatives à votre identité, vos
        coordonnées et l’adresse de votre logement.
        <br><br>
        <a class="boldy">Les plateformes de réseaux sociaux :</a> nous offrons la possibilité d’utiliser Wiplace et de
        se connecter via les
        réseaux sociaux et pouvons y recourir pour vous proposer les meilleures offres.
    </p>
    <br><br>
    <h3 id="sectionNumber6">6. Le stockage de vos informations</h3>
    <br>
    <p>
        Nous privilégions le plus possible de stocker vos informations au sein de l’Union européenne. Par exemple, nos
        serveurs sont situés au sein de l'Union Européenne.
        <br><br>
        Nous pouvons néanmoins recourir à des prestataires qui traitent les informations que nous leur fournissons en
        dehors de l’Union européenne. Lorsque cela se produit, nous veillons au préalable à ce que ce destinataire offre
        des garanties appropriées pour traiter vos informations avec un niveau de protection au moins équivalent à la
        protection que le droit de l’Union européenne vous offre.
    </p>
    <br><br>
    <h3 id="sectionNumber7">7. Le transfert de vos données</h3>
    <br>
    <p>
        Nous nous appliquons à conserver vos données personnelles au sein de l’Union Européenne.
    </p>
    <br><br>
    <h3 id="sectionNumber8">8. Notre utilisation des cookies </h3>
    <br>
    <p>
        Nous utilisons des cookies ainsi que des traceurs pour différentes raisons :
    </p>
    <p>
        <a class="boldy">8.1 Cookies techniques</a><br>
        &nbsp;Nous avons besoin de déposer ces cookies pour que vous puissiez utiliser les fonctionnalités de base de
        notre
        site internet et application et de vous proposer des fonctionnalités améliorées et personnalisées.<br><br>
        <a class="boldy">8.2 Cookies de mesure d’audience </a><br>
        &nbsp;Nous déposons ces cookies pour mieux comprendre la façon dont vous naviguez sur notre site internet et
        application, les contenus qui vont retenir votre attention, les pages que vous visitez le plus régulièrement,
        etc. Cela nous permet d’améliorer notre service.<br><br>
        <a class="boldy">8.3 Cookies réseaux sociaux / tiers</a><br>
        &nbsp;Nous déposons des cookies réseaux sociaux pour vous permettre de partager des contenus sur les principaux
        réseaux sociaux. Ces cookies (tiers) sont édités par les réseaux sociaux eux-mêmes. <br><br>
    </p>
    <br><br>
    <h3 id="sectionNumber9">9. Comment puis-je m’opposer à l’utilisation des cookies ?</h3>
    <br>
    <p>
        En paramétrant votre navigateur :<br>
        &nbsp;FIREFOX :<a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences"
            target="_blank">https://support.mozilla.org/fr/kb/activer-desactiver-cookies-preferences</a><br>
        &nbsp;GOOGLE CHROME :<a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=fr"
            target="_blank">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=fr</a><br>
        &nbsp;INTERNET EXPLORER :<a
            href="https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies "
            target="_blank">https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies</a><br>
        &nbsp;SAFARI :<a href="https://support.apple.com/fr-ch/guide/safari/sfri11471/mac"
            target="_blank">https://support.apple.com/fr-ch/guide/safari/sfri11471/mac</a><br>
    </p>
    <br><br>
    <h3 id="sectionNumber10">10. Vos droits</h3>
    <br>
    <p>
        Vous avez plusieurs droits sur les informations que nous possédons vous concernant:
    </p>
    <p>
        <a class="boldy">Droit d’accès</a><br>
        &nbsp;Vous pouvez nous demander d’accéder à toutes les informations que nous détenons sur vous.<br><br>
        <a class="boldy">Droit de rectification </a><br>
        &nbsp;Vous pouvez nous demander de corriger vos informations. <br>
        La plupart du temps, vous pouvez le faire vous-même lorsque vous êtes connecté à notre application.<br><br>
        <a class="boldy">Droit d’opposition</a><br>
        &nbsp;Vous pouvez nous demander d’arrêter d’utiliser vos informations lorsque nous le faisons en raison d’un
        intérêt légitime.<br>
        Vous pouvez également nous demander d’arrêter de recevoir des messages de prospection / marketing direct. Vous
        pouvez le faire facilement lorsque vous êtes connecté à notre application en allant sur l’onglet « Message» puis
        dans la conversation « Team Wiplace» ou en vous désinscrivant en cliquant sur le lien fourni en bas de nos
        emails.<br><br>
        <a class="boldy">Droit de retrait du consentement</a><br>
        &nbsp;Lorsque vous nous avez donné votre consentement pour utiliser vos informations pour un usage précis, vous
        pouvez à tout moment décider de retirer votre consentement et nous arrêterons alors d’utiliser vos informations
        pour l’usage en question. <br><br>
        <a class="boldy">Droit à l’effacement</a><br>
        &nbsp;Vous pouvez nous demander, par email à contactus@Wiplace.me , de supprimer vos informations ou que nous
        arrêtions de les utiliser. <br>
        Nous ne pouvons le faire que lorsque cela est possible : parfois nous avons besoin de vos informations pour
        respecter la loi ou simplement pour vous fournir nos services. <br><br>
        <a class="boldy">Droit à la limitation</a><br>
        &nbsp;Vous pouvez demander par email à contactus@Wiplace.me de verrouiller l’utilisation de vos informations
        c’est-à-dire de les conserver provisoirement mais de cesser de les utiliser.<br><br>
        <a class="boldy">Droit à la portabilité</a><br>
        &nbsp;Vous pouvez contacter notre équipe à contactus@Wiplace.me pour demander un export de vos informations
        dans un format réutilisable.
        <br><br>
        <a class="boldy">Droit d’introduire une réclamation</a><br>
        &nbsp;Vous pouvez vous plaindre devant l’autorité chargée de protéger les données personnelles (en France, la
        CNIL www.cnil.fr) si vous estimez que vos droits n’ont pas été respectés.<br><br>
    </p>
    <br><br>
    <h3 id="sectionNumber11">11. Exercer vos droits</h3>
    <br>
    <p>
        Pour exercer vos droits, contactez notre équipe à contactus@Wiplace.me <br>
        Nous ferons tout notre possible pour vous répondre dans un délai d’un mois. <br>
        Tout refus de demande sera documenté et explicité. <br>
        Un justificatif d’identité est demandé pour toute demande d’exercice de droit (sauf exceptions particulières).
    </p>
</div>

<app-footer></app-footer>
