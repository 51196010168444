<div class="row container-fluid navBar">

  <div class="col-md-2 col-sm-6 pt-1 d-flex justify-content-start">
    <a class="navItem" [routerLink]="'/'">
      <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />


    </a>
  </div>

  <div class="col-md-10 d-none d-md-block typography">
    <div class="row  pt-3 justify-content-end" style="margin-top: 7px;">
      <div class="col">
        <div><a class="navItem" [routerLink]="'/'" fragment="" [routerLinkActive]="['active']">Accueil</a>
        </div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/conditions'" [routerLinkActive]="['active']" fragment=""
            style="color: #0078E5">Condition générales
            d’utilisation</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/conditionsGénérales'" [routerLinkActive]="['active']"
            fragment="">Conditions
            générales de vente</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/charte'" [routerLinkActive]="['active']" fragment="">Charte
            de bonne conduite</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/confidentialityChart'" [routerLinkActive]="['active']"
            fragment="">Charte de
            confidentialité</a></div>
      </div>
    </div>
  </div>
</div>

<div class="main-div">
  <h1 class="h1">Conditions générales d’utilisation</h1>
  <br>

  <h4>Conditions d’utilisation</h4>
  <br>
  <p>
    L’utilisation de la plateforme Wiplace  est subordonnée à l’acceptation des conditions générales d’utilisation, des
    conditions générales de vente ainsi que de la charte de bonne conduite, le tout en totalité et sans réserve. L’accès
    à la
    plateforme est réservé aux individus âgés d’au moins 18 ans. L’utilisateur déclare avoir la jouissance pleine et
    régulière de son habitation en vertu d’un titre de propriété ou de tout autre titre l’autorisant à conclure sur ce
    bien, sans l’autorisation d’un tiers, pendant toute la durée de son adhésion aux conditions générales de vente, une
    convention de prêt à usage au sens des dispositions des articles 1875 et s. du Code civil français et à mettre
    gratuitement, dans ce cadre, son habitation à disposition d’un autre utilisateur de son choix, et, en particulier,
    jusqu’à l’expiration de la convention d’échange portant sur son habitation conclue avec ledit utilisateur.

    <br>
    <br>
    <br>


    Pour utiliser la plateforme Wiplace , l’utilisateur doit s’inscrire et créer un compte utilisateur en fournissant
    des données personnelles le concernant, indispensables au bon fonctionnement de la plateforme (nom, prénom, date de
    naissance, numéro de téléphone et adresse e-mail valide). L’utilisateur s’engage à fournir des renseignements
    véridiques, exacts et complets sur le site web et l’application ainsi qu’en général à Wiplace . L’utilisateur est
    tenu de créer un mot de passe qui lui permet d’accéder à son espace personnel.

    <br>
    <br>

    L’utilisateur s’engage à informer Wiplace  sans délai en cas de modification des informations communiquées au
    moment de l’inscription en procédant lui-même à ces modifications au sein de son espace personnel.
  </p>

  <br>
  <br>

  <h4>Mot de passe et données</h4>

  <br>

  <p>L’utilisateur s’engage à garder son mot de passe secret et confidentiel, ainsi que de garder son compte personnel
    en ne le divulguant à personne. Il s’engage également à ne pas créer de comptes supplémentaires dans le but d'abuser
    du fonctionnement du site, ou d'autres utilisateurs, ou de chercher à se faire passer pour un autre utilisateur. Si
    un utilisateur pense que quelqu'un a obtenu les détails de son compte, il doit immédiatement contacter notre service
    client afin de nous en informer. L’utilisateur est responsable envers Wiplace  de toutes les activités qui se
    produisent sur son compte. <br><br>


    Une preuve de l’exactitude de l’identité sera demandée à l’utilisateur souhaitant devenir un membre vérifié. Les
    données personnelles de tous les utilisateurs sont protégées en respect du règlement nᵒ 2016/679, dit règlement
    général sur la protection des données de l'Union européenne qui constitue le texte de référence en matière de
    protection des données à caractère personnel en Europe. L’utilisateur dispose d’un droit de regard et de
    rétractation au sujet de ses données. Pour appliquer ce droit, il est invité à nous contacter à l’adresse e-mail
    suivante : contactus@Wiplace .me<br><br>

    Le prénom et la ville de résidence d'un utilisateur postant une annonce de logement
    à l'échange sont visibles dans son annonce et ce pour tous les autres utilisateurs
    de la plateforme. Les autres informations personnelles concernant l'identité de
    l'utilisateur ne sont pas accessibles pour les autres. Seule exception,
    elles sont communiquées aux 2 parties ayant confirmé leur intention
    d'échanger un logement via la plateforme lors de l'établissement du contrat d'échange.
    <!-- À noter que les informations personnelles concernant l’identité de l’utilisateur,
     à l’exception de son prénom et
     sa ville de résidence, ne
    sont communiqués aux autres membres uniquement lorsqu’un échange est confirmé par les 2 parties. </p> -->

    <br>
    <br>

  <h4>Eligibilité des logements proposés sur la plateforme </h4>

  <br>

  <p>Toute personne physique et majeure abonnée à Wiplace  et justifiant d’un titre de location ou de propriété peut
    proposer son logement à l’échange si celui-ci rentre dans les critères définis par la présente :<br><br>

    Le logement doit être décent et meublé ainsi que justifier au minimum d’un couchage, un coin d’eau, des toilettes et
    un coin cuisine équipé d’accessoires nécessaires à l’élaboration de repas.
    Les utilisateurs s’engagent à fournir un logement d'une surface minimum de 10m2,
    qui ne constitue pas un risque pour la sécurité ou la santé du locataire et dans lequel il n'y a pas d'animaux
    nuisibles.
    Il doit faire l’objet d’une souscription à une assurance habitation valide (attention,
    certaines assurances peuvent refuser de couvrir les dommages pouvant survenir lors d’échange de logement). <br><br>

    Les place-trotters peuvent mettre à disposition tout type de logement d’habitation
    (appartement, maison) , soit en totalité (tout le logement accessible) , soit en partie
    (une partie accessible , notamment s’il s’agit d’une colocation).<br><br>

    Si le logement est habité par plusieurs personnes, il doit pouvoir permettre aux voyageurs de jouir d’un espace
    privé et intime. Il en va de la responsabilité du place-trotter de prévenir les autres habitants avant un
    échange.<br><br>

    La création de l’annonce de logement doit être réalisée avec sérieux et assiduité ainsi qu’être conforme à la
    réalité, sous peine d’être supprimée. Le place-trotter est tenu d’indiquer des informations véridiques concernant le
    logement ainsi que le code de conduite. S’il souhaite ajouter des informations complémentaires, il peut les
    renseigner dans les champs « description de l’annonce ».
  </p>

  <br>
  <br>

  <h4>Utilisation de la messagerie</h4>

  <br>

  <p>
    Les informations diffusées sur le service de messagerie de Wiplace  sont diffusées à l’initiative et sous la seule
    responsabilité des place-trotters. Les membres peuvent échanger librement et se mettre d’accord sur les modalités de
    l’échange et les services mis à disposition dans le cadre d’un échange. Cependant, ces informations ne sont pas
    susceptibles de créer une quelconque garantie de la part de Wiplace  sur leur exactitude, leur exhaustivité, leur
    caractère non trompeur ni même leur vraisemblance.<br><br>

    Wiplace , qui a accès aux informations échangées sur le Service de messagerie, se réserve par ailleurs le droit de
    retirer un contenu de ces messages, contraire aux CGU, à la loi ou qui serait jugé litigieux selon sa propre
    appréciation. </p>

  <br>
  <br>

  <h4>Processus de demande et de validation de l'échange</h4>

  <br>

  <p>Pour faire un échange avec d’autres utilisateurs, l’utilisateur doit réunir les conditions suivantes :</p>

  <ul>
    <li>Créer un compte.</li> <br>
    <li>Souscrire à l’abonnement Wiplace .</li> <br>
    <li>Faire valider son statut de membre vérifié.</li> <br>
    <li>Créer son annonce de logement.</li> <br>
  </ul>

  <p>Un utilisateur qui ne remplit pas une ou plusieurs de ses conditions ne pourra pas effectuer de demande
    d’échange.<br><br>

    Au moment d’effectuer sa demande d’échange, le place-trotter demandeur doit s’assurer de l’exactitude des
    informations renseignées dans son annonce ainsi que les dates souhaitées. En cas de non-conformité, les manquements
    relèvent de la responsabilité du propriétaire du compte ayant réalisé la demande.<br><br>

    Le place-trotter réceptionneur dispose alors d’un délai de quarante-huit (48) heures afin d’accepter ou refuser la
    proposition du place-trotter demandeur. Au-delà, la demande sera automatiquement annulée. Les place-trotters
    pourrons alors effectuer une nouvelle demande d’échange. La décision de l’échange n’est pas de la responsabilité de
    Wiplace , ainsi il est inutile de solliciter le service client qui ne pourra intervenir dans la décision de ses
    membres.<br><br>

    Une fois que le place-trotter réceptionneur accepte la demande,
    les 2 échangeurs réceptionneront la convention de prêt à usage relative
    à l’échange qui leur aura été transmis sur l’adresse mail fournie au moment de
    l’inscription. <br><br>

    Ils disposent d’un délai de sept (7) jours pour retourner le contrat signé à l’adresse mail
    contactus@Wiplace .me, sans quoi l’échange sera automatiquement annulé. Les 2 utilisateurs se doivent de tenir
    leurs engagements et de faire tout le nécessaire afin d’assurer le bon déroulement de l’échange.<br><br>

    Les membres entrés en relation sur Wiplace  en vue de la réalisation d’un échange s’engagent le cas échéant à
    concrétiser cet échange sur la plateforme par la signature d’une convention de prêt à usage soumise aux dispositions
    des articles 1875 et s. du Code civil français. </p>

  <br>
  <br>

  <h4>Processus de remise des clés</h4>

  <br>

  <p>Afin de récupérer les clés du logement dans lequel se rend le place-trotter, plusieurs moyens sont possibles en
    fonction du choix du propriétaire de l’habitation. Ainsi, il peut choisir de fournir les clés par ses propres moyens
    (remise pas un tiers, boîte à code …), dans ce cas il est responsable d’informer l’autre place-trotter sur les
    modalités de la remise des clés et doit s’assurer du bon déroulé de cette étape.<br><br>

    Quel que soit le mode de remise des clés privilégié, les 2 place-trotters sont tenus d’informer Wiplace  à chaque
    étape :</p>

  <ul>
    <li>Dépôt des clés de son logement</li><br>
    <li>Prise en main des clés du logement prêté</li><br>
    <li>Remise des clés du logement prété</li><br>
    <li>Reprise des clés de son logement</li><br>
  </ul>

  <p>Pour se faire, le place-trotter doit valider les étapes en cliquant sur le bouton « confirmer » de son application.
    En cas d’erreur, le place-trotter dispose de vingt-quatre (24) heures pour en informer notre service client.
  </p>

  <br>
  <br>

  <h4>Notes entre les membres</h4>

  <br>

  <p>À la fin de son séjour, les 2 place-trotters sont invités à noter la prestation fournie par leur hôte respectif sur
    cinq (5) ainsi que de laisser un commentaire. Cette action n’est pas obligatoire et reste à l’appréciation du
    place-trotter.
    L’avis laissé par le place-trotter ainsi que son prénom et sa photo de profil seront
    rattachés au profil du membre noté et visible par tous les autres membres qui consultent la page.
    Ces notes restent subjectives et ne peuvent à elle seule déterminer la valeur d’un hôte et de son logement. Il n’est
    pas possible de supprimer ou
    de modifier un avis une fois ce dernier publié. </p>

  <br>
  <br>

  <h4>Résolution des litiges</h4>

  <br>

  <p>Wiplace  est une plateforme de mise en relation. Elle ne peut être tenue comme responsable en cas de litige entre
    2 utilisateurs et ne peut donc intervenir dans leur résolution.<br><br>

    Pour cette raison, nous demandons à nos utilisateurs d’effectuer une demande d’assurance villégiature pour l’échange
    auprès de leur assureur habitation.<br><br>

    Cepandant, nous pouvons vous accompagner et vous conseiller au numéro suivant :
    <u><a href="tel:+33 6 37 96 03 49"> +33 6 37 96 03 49 </a>/ <a id="contact-btn" href="mailto:contactus@Wiplace .me">contactus@Wiplace .me</a></u>
  </p>
</div>
<app-footer></app-footer>
