import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public constructor(private titleService: Title) {
    this.titleService.setTitle('Wiplace  : Etudiants, voyagez en illimite toute l annee a  moindre frais !');
    // this.titleService.setTitle('Wiplace  : Etudiants, voyagez en illimité toute l\'année à moindre frais !');

  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  //title = 'Wiplace  | Partage de logement pour voyage';
}
