import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CharterPageComponent } from './charter-page/charter-page.component';
import { ConfidenceSecurityPageComponent } from './confidence-security-page/confidence-security-page.component';
import { ConfidentialityChartPageComponent } from './confidentiality-chart-page/confidentiality-chart-page.component';
import { ConfidentialityPolicyPageComponent } from './confidentiality-policy-page/confidentiality-policy-page.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { MailSuccessComponent } from './mailSuccess/mailSuccess.component';
import { OperatingModePageComponent } from './operating-mode-page/operating-mode-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ServiceConditionPageComponent } from './service-condition-page/service-condition-page.component';
import { Section5Component } from './welcome/section5/section5.component';
import { Section7Component } from './welcome/section7/section7.component';
import { WelcomePageComponent } from './welcome/welcome-page/welcome-page.component';
import {Section6Component} from "./welcome/section6/section6.component";

const routes: Routes = [
  {
    path: '',
    component: WelcomePageComponent
  },
  {
    path: 'faq',
    component: FaqPageComponent
  },
  {
    path: 'commentCaMarche',
    component: OperatingModePageComponent
  },
  {
    path: 'conditions',
    component: ServiceConditionPageComponent
  },
  {
    path: 'charte',
    component: CharterPageComponent
  },
  {
    path: 'confidentialityChart',
    component: ConfidentialityChartPageComponent
  },
  {
    path: 'conditionsGénérales',
    component: ConfidentialityPolicyPageComponent
  },
  {
    path: 'confidenceSecurité',
    component: ConfidenceSecurityPageComponent
  },
  {
    path: 'mail-sent-success',
    component: MailSuccessComponent
  },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' },
  {
    path: 'section7',
    component: Section7Component
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {

    useHash: false,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
