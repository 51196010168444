
<div class="close">
  <!-- <button   (click)="closeDialog()"> -->
  <!-- <img class="xIm" src="/assets/images/close.png"  (click)="closeDialog()"/> -->
  <!-- </button> -->
  <a class="xIm" (click)="closeDialog()">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18" cy="18" r="18" fill="#F4F8FB" />
      <g clip-path="url(#clip0_4066_9664)">
        <path
          d="M19.212 18.0006L27.7491 9.46342C28.0806 9.13199 28.0806 8.58342 27.7491 8.25199C27.4177 7.92056 26.8691 7.92056 26.5377 8.25199L18.0006 16.7891L9.46342 8.25199C9.13199 7.92056 8.58342 7.92056 8.25199 8.25199C7.92056 8.58342 7.92056 9.13199 8.25199 9.46342L16.7891 18.0006L8.25199 26.5377C7.92056 26.8691 7.92056 27.4177 8.25199 27.7491C8.42342 27.9206 8.64056 28.0006 8.8577 28.0006C9.07485 28.0006 9.29199 27.9206 9.46342 27.7491L18.0006 19.212L26.5377 27.7491C26.7091 27.9206 26.9263 28.0006 27.1434 28.0006C27.3606 28.0006 27.5777 27.9206 27.7491 27.7491C28.0806 27.4177 28.0806 26.8691 27.7491 26.5377L19.212 18.0006Z"
          fill="#011120" />
      </g>
      <defs>
        <clipPath id="clip0_4066_9664">
          <rect width="20" height="20" fill="white" transform="translate(8 8)" />
        </clipPath>
      </defs>
    </svg>
  </a>

</div>
<br>

<div mat-dialog-content class="d-flex flex-column">

  <div class="body">

    <img class="img" src="/assets/images/initPopUp1.svg" alt="image" />

    <div class="textCenter">
      <b>
        <h3>Profitez-en !</h3>
      </b>
      <p class="paragraph">Tous nos services sont en accès
        <br> gratuit et illimité.
      </p>
      <!-- <p class="month">Septembre 2022</p> -->
    </div>

    <table class="table">
        <tr>
          <td class="tdDownload" style="text-align: right;">
            <a  href="https://bit.ly/3JHp0Gs" target="_blank">
              <img height="44" src="/assets/images/googlePlay.svg" alt="image" class="tdImage"/>
            </a>
          </td>
          <td class="tdDownload" style="text-align: left;">
            <a  href="https://apple.co/3rVG2tX" target="_blank" >
              <img height="44" src="/assets/images/appStore.svg" alt="image" class="tdImage"/>
            </a>
          </td>
        </tr>
      </table>
      
   

  </div>

</div>
