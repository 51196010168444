import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confidentiality-chart-page',
  templateUrl: './confidentiality-chart-page.component.html',
  styleUrls: ['./confidentiality-chart-page.component.css']
})
export class ConfidentialityChartPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  toSection(section: string) {
    document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
  }
}
