<div class="main-div3 container-fluid">
  <h2 class="row d-flex justify-content-center">Comment ça marche </h2>
  <p class="mb-5">Échanger son logement, c’est super simple quand on y pense ! </p>

  <div class="row">

    <div class="col-lg-3 col-md-6 col-sm-12">
      <div class="d-flex justify-content-center">
        <img width="137" height="123" src="/assets/images/Framesection3Image1.svg" alt="image" />

      </div>
      <h4 class="item1 mt-4">Créez votre profil et votre annonce </h4>
      <p class="mt-4">Téléchargez l’application Wiplace  et complétez vos informations. </p>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-12">
      <div class="d-flex justify-content-center">
        <img class="img-fluid" src="/assets/images/group4.png" alt="image">
      </div>
      <h4 class="item1 mt-4">Explorez les destinations </h4>
      <p class="mt-4">Effectuez une recherche et visualisez en un coup d’œil les biens proposés. </p>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-12">
      <div class="d-flex justify-content-center">
        <img width="145" height="123" src="/assets/images/Framesection3Image3.svg" alt="image" />
      </div>
      <h4 class="item1 mt-4">Planifiez votre voyage </h4>
      <p class=" mt-4">Consultez le calendrier de disponibilité des logements qui vous intéressent. </p>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-12">
      <div class="d-flex justify-content-center">
        <img width="137" height="123" src="/assets/images/Framesection3Image4.svg" alt="image" />

      </div>
      <h4 class="item1 mt-4">Échangez avec des place-trotters  </h4>
      <p class="mt-4">Profitez de notre messagerie pour contacter d’autres membres et effectuer votre demande d’échange.
      </p>
    </div>

  </div>
</div>
