<div class="row container-fluid main-div1">

  <div class="col-md-2 col-sm-6 pt-1 d-flex justify-content-start">
    <a [routerLink]="'/'">
      <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />
    </a>
  </div>
  <!-- </div> -->

  <div class="col-md-10 d-none d-md-block typography">
    <div class="row  pt-3 justify-content-end" style="margin-top: 7px;">
      <div class="col">
        <div><a class="nav-link" [routerLink]="'/'" fragment="" [routerLinkActive]="['active']">Accueil</a>
        </div>
      </div>
      <div class="col">
        <div class="hvr"><a class="nav-link" [routerLink]="'/conditions'" [routerLinkActive]="['active']"
            fragment="">Condition générales d’utilisation</a></div>
      </div>
      <div class="col">
        <div class="hvr"><a class="nav-link" [routerLink]="'/conditionsGénérales'" [routerLinkActive]="['active']"
            fragment="">Conditions générales de vente</a></div>
      </div>
      <div class="col">
        <div class="hvr"><a class="nav-link" [routerLink]="'/charte'" [routerLinkActive]="['active']" fragment="">Charte
            de bonne conduite</a></div>
      </div>
      <div class="col">
        <div class="hvr"><a class="nav-link" [routerLink]="'/confidentialityChart'" [routerLinkActive]="['active']"
            fragment="">Charte de confidentialité</a></div>
      </div>
      <!--<div class="col p-2 dropdown">
        <button class="btn typography dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
          aria-expanded="false">
          Français
        </button>
        <ul class="dropdown-menu typography" aria-labelledby="dropdownMenuButton1">
          <li><a class="dropdown-item">Français</a></li>
          <li><a class="dropdown-item">English</a></li>
        </ul>
      </div>-->

    </div>
  </div>


</div>
