import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-light',
  templateUrl: './header-light.component.html',
  styleUrls: ['./header-light.component.css']
})
export class HeaderLightComponent implements OnInit {

  @Input() menuItems: any = [];
  
  constructor() { }

  ngOnInit() {
  }

}
