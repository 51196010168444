<div class="row container-fluid navBar">

  <div class="col-md-2 col-sm-6 pt-1 d-flex justify-content-start">
    <a class="navItem" [routerLink]="'/'">
      <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />
    </a>
  </div>

  <div class="col-md-10 d-none d-md-block typography">
    <div class="row  pt-3 justify-content-end" style="margin-top: 7px;">
      <div class="col">
        <div><a class="navItem" [routerLink]="'/'" fragment="" [routerLinkActive]="['active']">Accueil</a>
        </div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/conditions'" [routerLinkActive]="['active']" fragment="">Condition
            générales
            d’utilisation</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/conditionsGénérales'" [routerLinkActive]="['active']"
            fragment="">Conditions
            générales de vente</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/charte'" [routerLinkActive]="['active']" fragment=""
            style="color: #0078E5">Charte
            de bonne conduite</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/confidentialityChart'" [routerLinkActive]="['active']"
            fragment="">Charte de
            confidentialité</a></div>
      </div>

    </div>
  </div>
</div>

<div class="main-div">
  <h1 class="h1">Charte de bonne conduite</h1>
  <br>
  <h4>Code de conduite de l'utilisateur</h4>

  <br>

  <p>

    Wiplace est une plateforme de mise en relation constituant une communauté de voyageurs bienveillants où prône le
    respect d’autrui. Les comportements inappropriés n’y sont pas tolérés. <br><br>

    De façon général, tout type d’abus de nature à nuire à autrui ou à l’entreprise Wiplace est considéré comme
    contenu inapproprié. Voici une liste non exhaustive des comportements interdits à nos utilisateurs sur nos
    plateformes : <br><br>

    Le harcèlement d’un de nos membres ou membres potentiels <br><br>

    La publication ou même la participation à toute forme d'activité via le site qui soit illégale ou discriminante,
    assimilable à du harcèlement, diffamatoire, injurieuse, menaçante, nuisible, vulgaire, obscène, profane,
    sexuellement orientée, sexiste, raciste, offensive ou ayant un contenu répréhensible <br><br>

    La publication de contenu (texte / images / photos ou autre) dont l’utilisateur n’est pas le propriétaire ou n’en a
    pas reçu l’autorisation de la part du propriétaire du contenu. <br><br>

    Le fait de spammer les autres utilisateurs ou toute autre personne <br><br>

    La publication de ses coordonnées ou celles de quelqu’un d’autre hors échange confirmé et / ou autorisation de
    l’identitaire <br><br>

    Toute tentative d’enrichissement en profitant des services Wiplace et des autres utilisateurs.

    <br>
    <br>
    <br>


    Ces règles s’appliquent également lors des échanges.

    <br>
    <br>


    L’utilisateur ne respectant pas ces mesures peut voir son compte suspendu temporairement ou définitivement de
    l’application Wiplace sans aucune justification ni remboursement et s’expose à des répercutions judiciaires.
  </p>

  <br>
  <br>

  <h4>Comportement à adopter avant, pendant et après un échange </h4>

  <br>

  <p> Avant tout échange validé par la plateforme, les place-trotters doivent veiller à fournir un logement conforme à
    leur annonce, propre et décent. Lors de la prise des lieux, il doivent fournir à travers l’application Wiplace une
    preuve de leur arrivée en fournissant une photo de la porte d’entrée ainsi que de l'intérieur du logement. Pendant toute la durée du séjour,
    il veille à respecter le logement, les accessoires mis à disposition et le règlement du lieu.<br><br>

    Au moment du départ, il s’engage à rendre un logement rangé et propre, conforme avec l’état du logement à son
    arrivée. Il devra alors une nouvelle fois transmettre une photo de la porte et du logement, la procédure étant la même
    qu’à l’arrivée dans le logement.

    <br>
    <br>

    Un échange validé est une licence limitée pour entrer, occuper et utiliser l'hébergement. En cas de non-respect de
    l'heure de départ, votre hôte a le droit de faire partir le place-trotter d'une manière conforme au droit
    applicable, y compris en imposant des sanctions raisonnables pour séjour indûment prolongé.<br><br>

    Il est interdit de dépasser le nombre maximal autorisé de voyageurs.
  </p>

  <!-- <p>Commençons :</p> -->

  <br>

  <h4>La check-list d'un échange bien anticipé</h4>
  <br>
  <h6>Un échange parfait est un échange préparé. Pour vous aider, nous vous avons préparé une liste de choses à prévoir.
    Libre à vous de l’adapter en fonction de vos besoins.</h6>
  <br>
  <p>
    <b>Vérifiez l’autorisation de votre propriétaire</b><br>
    Certains propriétaires refusent que leurs locataires permettent à d’autres personnes d’utiliser leur logement.
    Nous demandons à nos utilisateurs de contrôler l’autorisation avant de proposer leur logement à l’échange via notre
    plateforme et de respecter la décision du bailleur en cas de refus.
  </p>
  <br>
  <p><b>Effectuez votre demande d’assurance villégiature</b>
    <br>
    L’assurance villégiature est incluse dans votre assurance habitation et correspond au report de votre assurance
    habitation sur votre lieu de séjour. Généralement gratuite, elle vous permet de bénéficier de votre responsabilité
    civile couvrant les dommages que vous êtes susceptibles de causer à des tiers notamment en cas de dommage sur le
    local et ses composantes, les dégâts des eaux et incendies. Elle vous protège donc pendant le séjour. Effectuez
    votre demande directement auprès de votre assureur. Si vous ne faites pas la demande,
    vous devez avoir conscience que vous ne serez pas protégé si un dégât intervient pendant votre échange et devrez
    rembourser par vos propres moyens.
  </p>
  <br>
  <p><b>Prévenez votre entourage</b>
    <br>
    Si vous proposez un logement partagé avec d’autres habitants (exemple colocation), prenez le temps de leur demander
    leur accord concernant échange.

    <br>Qu’importe votre logement, pensez à prévenir votre entourage et vos voisins de arrivée d’un nouvel habitant à
    votre domicile.
    Vous éviterez les inquiétudes de vos voisins et les problèmes qui peuvent en découler.
    <br>
  </p>
  <br>
  <p><b>Préparez la remise des clés</b><br>
    De nombreux systèmes existent afin de remettre les clés à votre hôte une fois qu’il arrive dans votre logement. Vous
    pouvez par exemple opter pour un coffre-fort installé proche de votre résidence ou prévoir une remise par un membre
    de votre entourage. Veillez à faire tout votre possible pour que votre hôte puisse récupérer les clés au moment de
    son arrivée.
    Informez-le au préalable sur le processus retenu afin de lui remettre les clés.
  </p>
  <br>
  <p><b>Laissez des instructions </b>
    <br>
    Afin de vous assurer que votre hôte utilise correctement le matériel mis à
    sa disposition dans le logement, notamment lorsque leur prise en main peut
    s’avérer compliquée, laissez- lui des indications détaillées.
    Pour ce qui concerne les installations complexes de type piscine,
    indiquez à votre hôte la procédure détaillée relative à l’entretien et
    respectez celles qui vous sont données. N’oubliez pas de préciser ce que
    votre hôte doit faire si vous avez des plantes ou
    des animaux qui demandent peu d’entretien.
  </p>
  <br>
  <p><b>Rassemblez vos objets privés </b><br>
    Si vous avez des objets de valeur (monétaire comme sentimentale) ou
    que vous ne souhaitez pas laisser à la disposition de l’hôte,
    rassemblez ces objets dans un coffre,
    une armoire ou pièce de préférence cadenassée,
    vous pouvez aussi les laisser chez un proche.
    Tout objet laissé en libre accès peut être utilisé par votre hôte,
    c’est donc à vous de prendre vos précautions pour restreindre l’utilisation des items sensibles.
  </p>
  <br>
  <p><b>Offrez un espace accueillant et propre</b>
    <br>
    Que ce soit au moment de quitter votre logement ou celui qui vous est prêté,
    il est demandé à nos utilisateurs de faire le ménage du lieu et de laisser à
    l'autre des draps et des serviettes propres. En règle générale,
    traitez le logement avec autant de respect que si c’était le vôtre et rendez
    le dans le même état que lors de votre arrivée. Si vous le souhaitez ,
    vous pouvez aussi demander à votre hôte s’il souhaite que vous lui laissiez des
    provisions pour le premier repas suivant son arrivée, ce qui peut être
    un geste très sympathique pour un hôte qui a un long voyage de nuit par exemple.
  </p>
  <br>
  <p><b>Respectez les logements qui vous sont prêtés</b><br>
    Même si cela peut paraître évident, veillez à respecter le logement, les commodités et les habitants que vous
    rencontrerez à chacun de vos échanges. En cas de problème (casse ou autre), informez-en sans délai votre hôte ainsi
    que votre assurance si nécessaire.

  </p>

  <br>
  <p><b>Réglez vos factures </b><br>
    Bien que vous ne soyez pas dans les lieux, vous devez impérativement continuer à régler vos différentes factures
    concernant l’eau / le gaz / l’électricité / le loyer / l’assurance ainsi que toute autre impératif financier relatif
    à votre habitation. L’échange avec un autre utilisateur ne vous substitue aucunement de vos obligations envers vos
    différents contractants.

  </p>
  <br>

  <!-- <p><b>Clés </b>- il existe de nombreuses options, des coffres-forts aux amis/famille ou voisins, assurez-vous que la
    solution retenue est la meilleure pour votre partenaire. Si vous confiez la clé à une personne de confiance,
    assurez-vous qu'elle sera disponible pour votre partenaire à son arrivée. Si vous laissez la clé à un endroit
    précis, assurez-vous que vous avez donné les instructions appropriées à votre partenaire.</p> -->

  <!-- <br>

    <b>Remise des clés:</b>

    <br>
    <br>

    <p>Votre hôte aura besoin de vos clés pour intègrer votre logement<br><br>

    Instructions spéciales concernant les équipements - la plupart des plateformes d'échange de maison vous permettent
    de visualiser les équipements de la propriété, de sorte que vous ne contacterez probablement pas un propriétaire qui
    ne dispose pas de vos "caractéristiques indispensables". Si la base de référence est acceptable, assurez-vous que le
    statut des commodités reste intact, et demandez des instructions d'utilisation spécifiques pour les appareils
    électriques, etc. Il est également acceptable que vous demandiez qu'un mode d'emploi abrégé soit mis à votre
    disposition.</p>

    <br>

    <p><b>Hors des zones limitées</b> - de nombreux échangeurs choisissent de garder leurs objets de valeur dans une pièce
    fermée à clé, un placard ou un coffre-fort. Assurez-vous de connaître les zones de la propriété dans lesquelles le
    propriétaire souhaite conserver son intimité et respectez-les.</p>

    <br>

  <p><b>Nettoyage et entretien</b> - Le nettoyage préliminaire peut sembler évident, mais il est bon de discuter des
    attentes mutuelles (surtout dans l'ère post COVID-19). Lorsque vous voyagez pour de longues périodes, un certain
    entretien de la propriété peut être nécessaire, comme la tonte de la pelouse, le nettoyage de la piscine,
    l'entretien ménager général, etc. Avant de partir, vous devez vous assurer que vous remettez la propriété dans son
    état initial. Si vous ne voulez pas faire le ménage vous-même, assurez-vous d'obtenir le contact du service de
    nettoyage du propriétaire et de prendre rendez-vous.</p>

    <br>


  <p><b>Ce que vous devez apporter</b> - en général, votre échangeur préparera du linge propre et des serviettes, si vous
    avez des problèmes, vous apporterez probablement les vôtres. Il convient de laver le linge et les serviettes avant
    votre départ, sauf accord contraire.</p>

  <p>Une autre chose importante que vous pouvez demander à votre swapper est de vous procurer les produits d'épicerie
    que
    vous aimez pour le premier jour, car nous savons tous que les vols peuvent être épuisants et qu'il n'y a rien de
    mieux que vos boissons et collations préférées qui vous attendent à votre arrivée.</p>

    <br>

  <p><b>Directives concernant le quartier</b> - tenez compte du fait que l'échange de maison est différent d'un hôtel.
    Vous logerez dans des appartements ou des maisons situés dans des immeubles et des quartiers résidentiels
    ordinaires, et vous devrez donc vous y fondre. Vérifiez si votre partenaire a des problèmes avec les voisins et s'il
    existe des règles ou des normes de voisinage.</p>

    <br>

  <p><b>Gestion du propriétaire</b> - si vous échangez avec un résident en location, vérifiez si l'échange est légal avec
    le propriétaire. Si vous préférez tous deux tenter votre chance, assurez-vous de savoir quoi dire au propriétaire
    s'il prévoit une visite surprise (bien que cette situation soit extrêmement rare).</p>

    <br>

    <p><b>Animaux domestiques</b> - les animaux domestiques ont des besoins différents. Nourrir un poisson ou s'occuper
        d'un chat est moins exigeant que de promener un chien trois fois par jour, alors assurez-vous d'être prêt à
        assumer ce type de responsabilité. Bien sûr, si vous partagez tous les deux les mêmes animaux, cela peut être
        une
        solution parfaite pour vous, mais assurez-vous d'obtenir des instructions très précises et de connaître le
        comportement général de l'animal. Les problèmes liés aux animaux sont la dernière chose que vous souhaitez
        pendant
        votre séjour.</p>

        <br>

        <p><b>Véhicules</b> - il y a de fortes chances que l'appartement ne soit pas le seul bien que votre partenaire
        d'échange laisse derrière lui. Il pourrait être propriétaire d'une voiture, d'une moto, d'un scooter, d'un vélo,
        et même d'une planche de surf, d'un snowboard, d'un ski... vous voyez le genre. Ce sont tous des biens que vous
        pouvez utiliser pendant votre séjour, mais assurez-vous d'avoir l'accord de votre partenaire et de disposer de
        tous les permis et polices d'assurance nécessaires.</p>

<br>

      <b>Voilà, c'est fait, vous êtes prêt !</b>

      <br>
      <br>

      <p>Si vous souhaitez étendre vos possibilités d'échange, assurez-vous de vous inscrire aux mises à jour de
        pré-lancement de notre nouvelle application SWAPLET - la seule plateforme d'échange de maisons basée sur le
        principe du freemium. Notre mission actuelle est d'établir une communauté d'adopteurs précoces pour essayer
        SWAPLET dès son lancement - et nous aurions bien besoin de vous en tant que membre de la communauté !</p> -->

</div>
<app-footer></app-footer>
