import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateExcelFileService } from 'src/app/services/updateExcelFile.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InitPopupComponent } from '../init-popup/init-popup.component';


@Component({
  selector: 'app-section1',
  templateUrl: './section1.component.html',
  styleUrls: ['./section1.component.css']
})
export class Section1Component implements OnInit {

  email: string = '';
  mailSaved: boolean = false;
  formatError: boolean = false;

  Emailform = new FormGroup({
    mail: new FormControl(this.email, [Validators.required, Validators.email])
  });


  constructor(private saveEmailService: UpdateExcelFileService, public dialog: MatDialog) { }

  ngOnInit() {
    this.dialog.open(InitPopupComponent, {
      height: '490px',
      width: '430px',
    });
  }


  saveEmail() {
    let emailAddress = {
      "email": this.email
    };
    if (this.Emailform.controls['mail'].valid) {
      this.saveEmailService.saveEmail(emailAddress).subscribe(
        data => {
          this.mailSaved = true;
          console.log(data)
        },
        error => console.log(error)
      );
    }
    else {
      this.formatError = true;
    }

  }

}
