import { HttpClient,HttpHeaders,HttpClientModule  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactMail } from '../interfaces/contactMail';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  endpointUrl = environment.apiUrl+'public/mail/contact';

  constructor(private http: HttpClient) { }

  sendMail(body: ContactMail) {
    // const headers = {
    //   /*'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTYzNTI1MzA2Mn0.9kJH4NcAS2o2CZ4Y44BM5aCwPUI7osmNnB4wzEzYyRkydZKUv2bFXPcOwVZ_AFYid4Mh5ylFXk_NQ24eOzdFDA',*/
    //   'Content-Type': 'application/json',
    //   'accept': '*/*'
    // };
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      'Content-Type': 'application/json',
      'accept': '*/*'
    })

    let options = { headers: headers };

        console.log(options);
    return this.http.post(this.endpointUrl + '/true', body, options);
  }

}
