<div class="row container-fluid navBar">

  <div class="col-md-2 col-sm-6 pt-1 d-flex justify-content-start">
    <a class="navItem" [routerLink]="'/'">
      <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />
    </a>
  </div>
  <!-- </div> -->

  <div class="col-md-10 d-none d-md-block typography">
    <div class="row  pt-3 justify-content-end" style="margin-top: 7px;">
      <div class="col">
        <div><a class="navItem" [routerLink]="'/'" fragment="">Accueil</a></div>
      </div>

    </div>
  </div>


</div>
<div class="main-div col justify-content-center align-items-center">
  <div class="row justify-content-center sub-div1">
    <img src="/assets/images/mailSuccess.svg" alt="image" style="width: 248px;" />
  </div>
  <h1 class="mb-3">Nous avons bien reçu votre formulaire!</h1>
  <div class="row justify-content-center">
    <span>Nous reviendrons vers vous dans les plus brefs délais. Si vous souhaitez plus d’information à ce sujet,</span>
    <!-- <h6 class="add col-md-2" (click)="onClick()" ><u>Cliquez-ici</u></h6> -->
    <br>
    <br>
    <button type="button" class="btn btn-primary" (click)="onClick()">Retour</button>
  </div>
</div>
<app-footer></app-footer>
