import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confidentiality-policy-page',
  templateUrl: './confidentiality-policy-page.component.html',
  styleUrls: ['./confidentiality-policy-page.component.css']
})
export class ConfidentialityPolicyPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
