<div class="justify-content-center align-items-center main-div4 container-fluid">
  <h2 class="mb-4 row d-flex justify-content-center">La meilleure solution pour les jeunes et étudiants</h2>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-lg-4 col-md-12 col-sm-12">
      <img class="img-fluid" src="/assets/images/group3.png" alt="image">
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
      <h6 id="sub-title">Nous avons avant tout créé Wiplace  pour faciliter votre mobilité.</h6>
      <br>
      <p id="desc">
        Entre partir en week-end ou en vacances et effectuer vos stages d'étude en France ou à l'étranger, ce n'est pas
        toujours facile de faire suivre le budget.
        <br><br>
        Avec Wiplace , rentabilisez votre logement étudiant. Grâce à notre abonnement à 60 euros par an, échangez en
        illimité et en toute sécurité.
        <br><br>
        Une fois le stage ou les vacances terminées, retrouvez votre logement et retournez en cours sereinement.
      </p>
    </div>

  </div>
</div>
