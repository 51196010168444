<div class="row container-fluid navBar">

  <div class="col-md-2 col-sm-6 pt-1 d-flex justify-content-start">
    <a class="navItem" [routerLink]="'/'">
      <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />

    </a>
  </div>
  <div class="col-md-10 d-none d-md-block typography">
    <div class="row  pt-3 justify-content-end" style="margin-top: 7px;">
      <div class="col">
        <div><a class="navItem" [routerLink]="'/'" fragment="">Accueil</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/commentCaMarche'" fragment="">Comment ça marche ?</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/faq'" fragment="">FAQ - Besoin d’aide</a></div>
      </div>
      <div class="col">
        <div><a class="navItem" [routerLink]="'/confidenceSecurité'" fragment="" style="color: #0078E5">Confiance et
            sécurité</a></div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid main-div">
  <h1 class="h1">Confiance et sécurité </h1> <br>
  <span> Parce que votre protection et celle de votre habitation sont au cœur de nos priorités,
    nous avons pris les mesures nécessaires pour assurer votre sécurité. </span>
  <div class="flex sub-div">
    <div class="container">
      <div class="mb-4">
        <img src="/assets/images/picture6.png" alt="picture">
      </div>
      <h4 class="item item1 mb-4">Vérification des identités</h4>
      <p class="item">Chaque membre proposant un bien à l’échange sur notre plateforme  voit son identité vérifiée par nos soins en nous transmettant une copie de
        sa carte d’identité / passeport à la page photo / permis de conduire
        en cours de validité.
      </p>
    </div>

    <div class="container">
      <div class="mb-4">
        <img src="/assets/images/picture5.png" alt="picture">
      </div>
      <h4 class="item item1 mb-4">Vérification des logements</h4>
      <p class="item">Chaque annonce fait l’objet d’une attention toute particulière pour contrôler le titre de propriété ou de location 
        ainsi que l’assurance habitation attenants. </p>
    </div>

    <div class="container">
      <div class=" mb-4">
        <img src="/assets/images/picture4.png" alt="picture">
      </div>
      <h4 class="item item1 mb-4">Sécurité du paiement</h4>
      <p   class="item">Offrez-vous des voyages illimités en toute sécurité sur Wiplace.  Vos données sont cryptées et ne sont pas
        sauvegardées afin de garantir votre sécurité. </p>
    </div>
  </div>

  <div class="flex sub-div">
    <div class="container">
      <div class="mb-4">
        <img src="/assets/images/picture3.png" alt="picture">
      </div>
      <h4 class="item item1 mb-4">Protection des données </h4>
      <p  class="item">Vos informations (nom de famille, adresse postale et e-mail, numéro de téléphone … ) sont protégées et ne sont
        communiquées aux autres utilisateurs que lorsqu’un échange est prévu entre 2 place-trotters. </p>
    </div>

    <div class="container">
      <div class="mb-4">
        <img src="/assets/images/picture2.png" alt="picture">
      </div>
      <h4 class="item item1 mb-4">Intégrité et respect de l’autre</h4>
      <p  class="item">Wiplace est une communauté de voyageurs bienveillants et désireux de bien vivre ensemble. Tout utilisateur ne
        respectant pas notre charte de bonne conduite est exclu de notre plateforme. </p>
    </div>

    <div class="container">
      <div class="mb-4">
        <img src="/assets/images/picture1.png" alt="picture">
      </div>
      <h4 class="item item1 mb-4">Remise des clés </h4>
      <p  class="item">Afin de faciliter vos échanges de clés entre Place-trotters, nous conseillons à nos clients différents moyens
        pour la remise des clés. Vous pouvez demander à un de vos proches (voisin, ami, famille …) de l’effectuer à
        votre place </p>
    </div>
  </div>
</div>
<app-footer></app-footer>
