<div class="main-div2 col container-fluid">
  <div class="col-12 ">
    <h2 class="row d-flex justify-content-center">Vivez une expérience unique à petit budget </h2>
    <p class="mb-5">Week-end à la mer, visite de sa ville natale, déplacement professionnel ou festival, on a tous une
      raison
      d’utiliser <b>Wiplace</b>
    </p>
  </div>

  <div class="row">

    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
      <div class="d-flex justify-content-center">
        <img width="195" height="197" src="/assets/images/section2Image1.svg" alt="image" />
      </div>
      <h4 class="mt-4">Sans rien dépenser </h4>
      <p class="mt-4">Laissez le budget logement aux oubliettes et profitez de votre destination </p>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
      <div class="d-flex justify-content-center">
        <img width="195" height="197" src="/assets/images/section2Image2.svg" alt="image" />
      </div>
      <h4 class="mt-4">En toute sécurité </h4>
      <p class="mt-4">Partez l’esprit léger grâce à nos mesures de protection </p>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
      <div class="d-flex justify-content-center">
        <img width="195" height="197" src="/assets/images/section2Image3.svg" alt="image" />
      </div>
      <h4 class="mt-4">Où vous voulez </h4>
      <p class="mt-4">Des Place-trotters de la France entière proposent leur logement à l’échange </p>
    </div>

    <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
      <div class="d-flex justify-content-center">
        <img width="195" height="197" src="/assets/images/section2Image4.svg" alt="image" />
      </div>
      <h4 class="mt-4">Quand vous voulez </h4>
      <p class="mt-4">Voyagez en illimité et quand vous le souhaitez pour la durée de votre choix </p>
    </div>





  </div>
</div>
