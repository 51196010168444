import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mailSuccess',
  templateUrl: './mailSuccess.component.html',
  styleUrls: ['./mailSuccess.component.css']
})
export class MailSuccessComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
onClick(){
  this.router.navigate(['/']);
}
}
