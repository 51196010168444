<div class="row container-fluid main-div1">

    <div id="div1" class="col-md-4 col-sm-6">
        <a id="logo" [routerLink]="'/'">
            <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />
        </a>
    </div>

    <div id="div2" class="col-md-8 d-none d-md-block typography">
        <div id="navbar" class="row justify-content-end">

            <div class="col pt-2">
                <div class="hvr"><a class="nav-link" [routerLink]="['/']">Accueil</a></div>
            </div>
            <div class="col pt-2">
                <div class="hvr"><a class="nav-link" [routerLink]="['/']" fragment="how-it-works"
                        [routerLinkActive]="['active']" (click)="toSection('commentCaMarche')">Comment ça marche</a>
                </div>
            </div>
            <div class="col pt-2">
                <div class="hvr"><a class="nav-link" [routerLink]="['/']" fragment="become-partner"
                        (click)="toSection('devenirPartenaire')" [routerLinkActive]="['active']">Devenir partenaire
                        BDE</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="backgroudColor">
    <app-section1 id="about"></app-section1>
    <app-section2></app-section2>
    <app-section3 id="commentCaMarche"></app-section3>
    <app-section4></app-section4>
    <app-section7></app-section7>
    <app-section6 id="devenirPartenaire"></app-section6>
</div>
<app-footer></app-footer>
