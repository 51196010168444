<div class="backgroudColor col-12">
  <div class="main-div container-fluid col-12 pb-5">

    <img  style="width: 15em"src="/assets/images/logo_new.svg" alt="image" />
    <div class="row justify-content-around">
      <div class="col-md-12  col-xl-4 col-lg-5 col-sm-12">
        <div class="aline col-lg-2 col-sm-5">

          <h6><b>À propos</b></h6>
          <p d-flex justify-content-between>
            En tant que jeune startup française,
            Wiplace  a pour objectif de permettre à tous les place-trotters de voyager
            partout en France et bientôt dans le monde entier,
            à moindres frais.
          </p>
          <span class="d-flex justify-content-between ">
            <div class="col">
              <a href="https://bit.ly/3JHp0Gs" target="_blank">
                <img src="/assets/images/googlePlay.svg" alt="image" style="width:123px;height:39px" />
              </a>
            </div>
            <div class="col svg2">
              <a href="https://apple.co/3rVG2tX" target="_blank">
                <img src="/assets/images/appStore.svg" alt="image" style="width:123px;height:39px" />
              </a>
            </div>
          </span>
        </div>

        <!--<div class="pt-2 dropdown">
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton1"
            data-bs-toggle="dropdown" aria-expanded="false">
            Français
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" [routerLink]="'#'">Français</a></li>
            <li><a class="dropdown-item" [routerLink]="'#'">English</a></li>
          </ul>
        </div>-->
      </div>

      <div class="col-md-12 col-lg-3 col-sm-12">
        <h6><b>Utilisateur</b></h6>
        <p><a [routerLink]="'/commentCaMarche'"> Comment ça marche</a></p>
        <p><a [routerLink]="'/faq'"> FAQ-Besoin d’aide</a></p>
        <p><a [routerLink]="'/confidenceSecurité'"> Confiance et sécurité</a></p>

        <h6><b>Légal</b></h6>
        <p><a [routerLink]="'/conditions'"> Conditions générales d’utilisation</a></p>
        <p><a [routerLink]="'/conditionsGénérales'"> Conditions générales de vente</a></p>
        <p><a [routerLink]="'/charte'"> Charte de bonne conduite</a></p>
        <p><a [routerLink]="'/confidentialityChart'"> Charte de confidentialité </a></p>
      </div>

      <!--<div class="col-md-6 col-lg-3 col-sm-12">
        <h6><b>Partenaire BDE</b></h6>
        <p>Service d’aide au BDE</p> <br><br><br>

        <h6><b>Légale</b></h6>
        <p><a [routerLink]="'/conditions'"> Condition de services</a></p>
        <p><a [routerLink]="'/conditions'"> Paramétres de confidentialité</a></p>
        <p><a [routerLink]="'/charter'"> Charte de bonne conduite</a></p>
      </div>-->

      <div class="col-md-12 col-lg-2 col-sm-12">
        <h6><b>Suivez-nous</b></h6>

        <div class="d-flex justify-content-between flex-fill">
          <a href="https://www.facebook.com/Wiplace .me/" target="_blank">
            <img width="28" height="28" src="/assets/icons/Facebook.svg" alt="image" />
          </a>
          <a href="https://twitter.com/Wiplace _me?t=ydmgAb2DOMivGM_FGOxW8Q&s=09" target="_blank">
            <img width="28" height="28" src="/assets/icons/twitter.svg" alt="image" />
          </a>
          <a href="https://www.linkedin.com/company/Wiplace -me/" target="_blank">
            <img width="28" height="28" src="/assets/icons/linkedin.svg" alt="image" />
          </a>
          <a href="https://instagram.com/Wiplace .me?utm_medium=copy_link" target="_blank">
            <img width="28" height="28" src="/assets/icons/instagram.svg" alt="image" />
          </a>
        </div>

        <h6><b>Contact</b></h6>
        <div fxLayout="row">
          <img class="img-fluid" src="/assets/icons/pin.svg" alt="image">
          <span> 11 rue Charles Rossignol, 91600, Savigny-Sur-Orge</span>
        </div>
        <br>
        <div fxLayout="row">

          <img class="img-fluid" src="/assets/icons/envelop.svg" alt="image">
          <span> contactus@Wiplace .me</span> <br>
        </div>
        <br>
        <div fxLayout="row">
          <img class="img-fluid" src="/assets/icons/phone.svg" alt="image">
          <span> +33 6 37 96 03 49 </span> <br>
        </div>
      </div>
    </div>
    <br>
    <span style="float: inline-end;">© {{currentYear}} Wiplace  SAS</span>

  </div>
</div>
