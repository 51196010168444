import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-init-popup',
  templateUrl: './init-popup.component.html',
  styleUrls: ['./init-popup.component.css']
})
export class InitPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<InitPopupComponent>) { }
  ngOnInit() {
  }

  closeDialog() {
      this.dialogRef.close();
    }
   
}
