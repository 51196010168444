<div class="container-fluid">
    <div class="card-img-overlay">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 sub-di img-move-wrapper">
          <img id="pic1" class="img-fluid" src="/assets/images/overlay1.png" alt="screenshot1">
          <img id="pic2" class="img-fluid" src="/assets/images/overlay2.png" alt="screenshot2">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 sub-div">
          <h1>Prêt a vous lancer ?</h1>
          <p>Nous mettons tout en œuvre pour vous permettre d'explorer le monde et créer des relations en toute sécurité,
            sans débourser de frais. Si vous avez une question à nous poser ou besoin d’être rassuré, notre équipe est là
            pour vous écouter : </p>
        </div>
      </div>
      
    </div>
</div>