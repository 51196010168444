import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './shared/footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ServiceConditionPageComponent } from './service-condition-page/service-condition-page.component';
import { ConfidentialityPolicyPageComponent } from './confidentiality-policy-page/confidentiality-policy-page.component';
import { CharterPageComponent } from './charter-page/charter-page.component';
import { Section3Component } from './welcome/section3/section3.component';
import { Section4Component } from './welcome/section4/section4.component';
import { Section1Component } from './welcome/section1/section1.component';
import { WelcomePageComponent } from './welcome/welcome-page/welcome-page.component';
import { OperatingModePageComponent } from './operating-mode-page/operating-mode-page.component';
import { CreateRequestSectionComponent } from './create-request-section/create-request-section.component';
import { HeaderLightComponent } from './shared/header-light/header-light.component';
import { ConfidenceSecurityPageComponent } from './confidence-security-page/confidence-security-page.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { Section2Component } from './welcome/section2/section2.component';
import { Section6Component } from './welcome/section6/section6.component';
import { Section5Component } from './welcome/section5/section5.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { MailSuccessComponent } from './mailSuccess/mailSuccess.component';
import { Section7Component } from './welcome/section7/section7.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfidentialityChartPageComponent } from './confidentiality-chart-page/confidentiality-chart-page.component';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { HeaderLightTwoComponent } from './shared/header-light-two/header-light-two.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    ServiceConditionPageComponent,
    ConfidentialityPolicyPageComponent,
    CharterPageComponent,
    Section3Component,
    Section4Component,
    Section1Component,
    Section2Component,
    Section5Component,
    Section6Component,
    WelcomePageComponent,
    OperatingModePageComponent,
    CreateRequestSectionComponent,
    HeaderLightComponent,
    ConfidenceSecurityPageComponent,
    FaqPageComponent,
    MailSuccessComponent,
    Section7Component,
    ConfidentialityChartPageComponent,
    HeaderLightTwoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule,
    MatDialogModule,
    Ng2PageScrollModule,

  ],
  providers: [
    Title,
    { provide: LocationStrategy, useClass: HashLocationStrategy }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
