<div class="row container-fluid main-div1" (scroll)="onWindowScroll();">

  <div id="div1" class="col-md-4 col-sm-6">
    <a id="logo" [routerLink]="'/'">
      <img  style="width: 15em;" src="/assets/images/logo_new.svg" alt="logo" />
    </a>
  </div>

  <div id="div1" class="col-md-8 d-none d-md-block typography">
    <div class="row justify-content-end">
      <div class="col" *ngFor="let item of menuItems">
        <div class="hvr"><a [routerLink]="[item.path]" fragment={{item.fragment}} (click)="onEdit()">{{item.name}}</a>
        </div>
      </div>

    </div>
  </div>
</div>
